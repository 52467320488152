import React, { Component,Fragment } from 'react';
import { environment } from '../config/config'
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Routes from '../Routes'
import $ from 'jquery';
import PdfDocument from './PdfDocument';
import {PDFDownloadLink } from "@react-pdf/renderer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Authentication } from '../services/authentication';

class Cotizador extends Component{

    url = environment.url;

    constructor(props){
        super(props);

        if (!Authentication.isLogin()) {
            this.props.history.push('/');
        }

        this.state = {
            show247 : false,
            showVerifacion : false,
            showSocioeconomico: false,
            showPsicometria: false,
            showReclutamiento: false,
            plataformaCosto: [],
            verificacionCostos: [],
            socioeconomicoCostos:[],
            psicometriaCosto:[],
            reclutamientoGarantia:[],
            honorarios:[],
            psicometrico:{},
            plataforma247:[],
            verificacion:[],
            socioeconomico:[],
            psicometria:[],
            reclutamientos:[],
            asesor:[],
            estados:[],
            selPlataforma:[],
            selVerificaciones:[],
            selSocioeconimicos:[],
            selSocioeconimicoPaqs: [],
            selSocioeconimicoCodes: [],
            selPsicometrico:[],
            selPsicometricoPaq:[],
            selReclutamiento:[],
            selGarantia:[],
            selHonorario:[],
            selAsesor:{},
            selEstado:{},
            selSucursal:0,
            cotizacion:{},
            idCotizacion: 0,
            nCotizacion: 0,
            cotPlataforma:{},
            cotVerificacion:{},
            cotSocioeconomico:{},
            cotPsicometrica:[],
            cotReclutamiento:[],
            psicPro:[],
            psicPrem:[],
            psicOps:[],
            dupPsic: false,
            gridPsic:HTMLElement,
            open: false,
            email:false,
            emailasesor:'',
            emailcliente:'',
            plt247:[],
            totPlataforma:0,
            totPlataformas:[],
            totSocioeconomico:0,
            totSocioeconomicos:[],
            totVerificacion:0,
            totVerificaciones:[],
            totPsicometrias:[],
            totPsicometria:0,
            total:0,
            codeReclutamiento:[],
            pdf:'',
            valid: 0,
            pdfshow:false,
            nombrePDF:'',
            idcli:undefined,
            cliente:'',
            nPlataforma:[],
            nVerificacion:[],
            nSocioeconomicos:[],
            nPsicometrias:[],
            nReclutamientos:[],
            descripcion:'',
            dataPdf:[]
        }


        this.changePlataforma = this.changePlataforma.bind(this);
        this.changeVerificacion = this.changeVerificacion.bind(this);
        this.changeSocioeconomico = this.changeSocioeconomico.bind(this);
        this.changePsicometria = this.changePsicometria.bind(this);
        this.changePsicometriaPaq = this.changePsicometriaPaq.bind(this);
        this.changeReclutamiento = this.changeReclutamiento.bind(this);
        this.changeGarantia = this.changeGarantia.bind(this);
        this.changeHonorarios = this.changeHonorarios.bind(this);
        this.psicometrico = this.psicometrico.bind(this);
        // this.changeSucursal = this.changeSucursal.bind(this);
        this.changeEstado = this.changeEstado.bind(this);
        this.changeTotalPlataforma = this.changeTotalPlataforma.bind(this);
        this.changeTotalSocioeconomico = this.changeTotalSocioeconomico.bind(this);
        this.changeTotalVerificacion = this.changeTotalVerificacion.bind(this);
        this.changeTotalotroVerif = this.changeTotalotroVerif.bind(this);
        this.changeTotalPsicometria = this.changeTotalPsicometria.bind(this);
        // this.validate = this.validate.bind(this);
        this.getDataClient = this.getDataClient.bind(this);
        this.addP = this.addP.bind(this);
        this.removeP = this.removeP.bind(this);
        this.addS = this.addS.bind(this);
        this.removeS = this.removeS.bind(this);
        this.addV = this.addV.bind(this);
        this.removeV = this.removeV.bind(this);
        this.addPS = this.addPS.bind(this);
        this.removePS = this.removePS.bind(this);
        this.addR = this.addR.bind(this);
        this.removeR = this.removeR.bind(this);
        // this.validateSucursal = this.validateSucursal.bind(this);
        this.reclutamientos = this.reclutamientos.bind(this);
        // this.getPlataforma = this.getPlataforma.bind(this);
        this.moneyFormat = this.moneyFormat.bind(this);

        this.getClient();
        this.plataforma247();
        this.verificacion();
        this.socioeconomico();
        this.psicometria();
        this.reclutamientos(0);
        this.estados();
        this.psicPro();
        this.psicPrem();
    }

    //GET
    async plataforma247(){
        await axios.get(`${this.url}/api/services/plataforma247`)
        .then(result => {
                console.log(result.data);
                this.setState({plataforma247:result.data});
                console.log(this.state.plataforma247);
        });
    }

    async verificacion(){
        await axios.get(`${this.url}/api/services/verificacion`)
        .then(result => {
                console.log(result.data);
                this.setState({verificacion:result.data});
                console.log(this.state.verificacion);
        });
    }

    async socioeconomico(){
        await axios.get(`${this.url}/api/services/socioeconomico`)
        .then(result => {
                console.log(result.data);
                this.setState({socioeconomico:result.data});
                console.log(this.state.socioeconomico);
        });
    }

    async psicometria(){
        await axios.get(`${this.url}/api/services/psicometria`)
        .then(result => {
                console.log(result.data);
                this.setState({psicometria:result.data});
                console.log(this.state.psicometria);
        });
    }

    async reclutamientos(n){
        await axios.get(`${this.url}/api/services/reclutamientos`)
        .then(result => {
            this.state.reclutamientos[n] = result.data;
            this.setState({reclutamientos:this.state.reclutamientos});
        });
    }

    async estados(){
        await axios.get(`${this.url}/api/cotizador/estados`)
        .then(result => {
            this.setState({estados:result.data});
        })
    }

    async psicPro(){
        await axios.get(`${this.url}/api/services/psicPro`)
        .then(result => {
            this.setState({psicPro:result.data});
        })
    }

    async psicPrem(){
        await axios.get(`${this.url}/api/services/psicPrem`)
        .then(result => {
            this.setState({psicPrem:result.data});
        })
    }
    
    async reclutamiento(reclutamiento,n){
        await axios.get(`${this.url}/api/services/reclutamiento?rec=${reclutamiento}`)
        .then(response => {
                this.state.reclutamientoGarantia[n] = response.data;
                this.setState({reclutamientoGarantia:this.state.reclutamientoGarantia});
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async honorarios(code,n){
        await axios.get(`${this.url}/api/services/honorarios?code=${code}`)
        .then(response => {
            this.state.honorarios[n] = response.data;
            this.setState({honorarios:this.state.honorarios});
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async add(cot,enviar,pdfDownload){
    // async add(enviar){
        let usr = JSON.parse(localStorage.getItem('currentUser'));
        let cotizacion = {
            idCliente: localStorage.cli,
            idAsesor:usr.user.idasesor,
            idUsuario:usr.user.idusuario,
            total: this.state.totPlataforma + this.state.totVerificacion + this.state.totSocioeconomico + this.state.totPsicometria
        }
        await axios.post(`${this.url}/api/cotizador/save`,{cotizacion})
        .then(response => {
            this.setState({
                cotizacion: cotizacion,
                idCotizacion: response.data[0]["idcotizacion"],
                nCotizacion: response.data[0]["ncotizaciones"],
                email: enviar
            })
            this.addInfo(pdfDownload);
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async addInfo(pdfDownload){
    // async addInfo(){
        let cotizacion = {};
        cotizacion.idCotizacion = this.state.idCotizacion;
        cotizacion.nCotizacion = this.state.nCotizacion;
        cotizacion.plataforma = this.state.cotPlataforma;
        cotizacion.verificacion = this.state.cotVerificacion;
        cotizacion.socioeconomico = this.state.cotSocioeconomico;
        cotizacion.psicometrico = this.state.cotPsicometrica;
        cotizacion.reclutamiento = this.state.cotReclutamiento;
        // cotizacion.total = this.state.totPlataforma + this.state.totVerificacion + this.state.totSocioeconomico + this.state.totPsicometria;
        // cotizacion
        await axios.post(`${this.url}/api/cotizador/details`,{cotizacion})
        .then(response => {
            if(response.data != undefined){

                if(pdfDownload === true)
                    this.pdf();
                else
                    this.props.history.push('/cotizaciones');
            }
            // if(response.data === "success")
            //     this.props.history.push('/Cotizaciones');

            // this.pdf();
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async pdf(){
        // await axios.get(`${this.url}/api/cotizador/pdf?id=${59}`)
        await axios.get(`${this.url}/api/cotizador/pdf?id=${this.state.idCotizacion}`)
        .then(response => {
            // console.log(response.data);
            this.setState({nombrePDF:response.data.detalles.folio});
            this.setState({open:false});
            // this.createPdf(response.data);
            this.setState({dataPdf:response.data});
            
            if(response.data.plataforma.length > 0){
                this.getModulos(response.data.plataforma);
            }else{
                this.createPdf(this.state.dataPdf);
            }
            // this.sendEmail();
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async getModulos(plataforma){
        await axios.post(`${this.url}/api/cotizador/modules`,{plataforma})
        .then(response => {

            this.state.dataPdf.plataforma = response.data;
            this.setState({dataPdf:this.state.dataPdf});
            this.createPdf(this.state.dataPdf);
            // this.sendEmail();
        })
        .catch(error => {
            console.log(error.response)
        });
    }


    async sendEmail(blob){

        let email = {
            fileName: this.state.nombrePDF,
            to: this.state.emailcliente,
            from: this.state.emailasesor,
            asesor: this.state.descripcion,
            cliente: this.state.cliente
        }

        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async function() {
            var base64data = reader.result;
            email.file = base64data;

            //  await axios.post(`http://localhost:5000/api/cotizador/email`,{email})
             await axios.post(`https://cotizador.api.integrarh.mx/api/cotizador/email`,{email})
            .then(response => {
                // console.log(response.data);
                window.location.href = "/cotizaciones";
                //    this.props.history.push('/Cotizaciones');
            })
            .catch(error => {
                console.log(error.response)
            });

        }

    }

    validate(flagModal){
        let cont = 0;
        let plataforma=undefined,verificacion=undefined,socioeconomico=undefined,psicometrico=undefined,reclutamiento=undefined;

            plataforma = this.getPlataforma();
            // console.log(plataforma);
            if(plataforma !== undefined){
                cont = cont +1;
                this.setState({cotPlataforma:plataforma});
            }

            verificacion = this.getVerificacion();
            // console.log(verificacion);
            if(verificacion !== undefined){
                cont = cont +1;
                this.setState({cotVerificacion:verificacion});
            }

            socioeconomico = this.getSocioeconimico();
            // console.log(socioeconomico)
            if(socioeconomico !== undefined){
                cont = cont +1;
                this.setState({cotSocioeconomico:socioeconomico});
            }

            psicometrico = this.getPsicometrico();
            // console.log(psicometrico);
            if(psicometrico !== undefined){
                cont = cont +1;
                this.setState({cotPsicometrica:psicometrico});
            }

            reclutamiento = this.getReclutamiento();
            // console.log(reclutamiento);
            if(reclutamiento !== undefined){
                cont = cont +1;
                this.setState({cotReclutamiento:reclutamiento});
            }

            if(cont > 0){

                if(flagModal === true)
                    this.setState({open:true});
                else
                    this.add(this.state.cotizacion,false,true);

            }
    }

    getClient(){
        document.getElementById('root').style.marginLeft = "0px";
        if(localStorage.cli != undefined){
            this.getDataClient(localStorage.cli);
        }else{
            this.setState({idcli:undefined})
        }
    }

    async getDataClient(idcli){
        await axios.get(`${this.url}/api/clientes/data?cli=${idcli}`)
        .then(response => {
            this.setState({
                idcli: idcli,
                cliente: response.data[0].empresa,
                emailcliente: response.data[0].email,
                emailasesor: response.data[0].correo,
                descripcion: response.data[0].descripcion
            })
            // this.getClientData();
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    getPlataforma(){
        if(this.state.show247===true && this.state.selPlataforma.length > 0 ){
            let plataforma = [];
            this.setState({valid:this.state.valid+1});
            for(let i=0;i<this.state.selPlataforma.length;i++){
                plataforma.push({
                    idPaquete: this.state.selPlataforma[i].value.id,
                    costo: document.getElementById("txtPlataformaCosto"+i.toString()).value,
                    colaboradores: document.getElementById("txtPlataformaColaborador"+i.toString()).value,
                    total: this.state.totPlataformas[i]
                })
            }
            return plataforma;
        }
    }

    getVerificacion(){
        if(this.state.showVerifacion === true && this.state.selVerificaciones.length > 0){
            let verificaciones = []
            this.setState({valid:this.state.valid+1})
            for(let i=0; i<this.state.selVerificaciones.length;i++){
                verificaciones.push({
                    idPaquete: this.state.selVerificaciones[i].value.id,
                    cantidad: this.getVerificacionCantidad(i),
                    costo: this.getVerificacionCosto(i),
                    total: this.state.totVerificaciones[i]
                })
            }
            return verificaciones;
        }
    }

    getSocioeconimico(){
        if(this.state.showSocioeconomico === true && this.state.selSocioeconimicos.length > 0){
            let socioeconomicos = [];
            this.setState({valid:this.state.valid+1});
            for(let i=0;i<this.state.selSocioeconimicos.length;i++){
                socioeconomicos.push({
                    idPaquete: this.state.selSocioeconimicos[i].value.id,
                    cantidad: this.getSocioeconimicoCantidad(i),
                    costo: this.getSocioeconimicoCosto(i),
                    total: this.state.totSocioeconomicos[i]
                })
            }
            return socioeconomicos;
        }
    }

    getPsicometrico(){
        if(this.state.showPsicometria === true && this.state.selPsicometrico.length > 0){
            let psicometricos = [];
            this.setState({valid:this.state.valid+1})
            for(let i=0;i<this.state.selPsicometrico.length;i++){
                psicometricos.push({
                    idPaquete: this.state.selPsicometrico[i].value.id,
                    cantidad: this.state.selPsicometricoPaq[i].value.cantidad,
                    total: this.state.selPsicometricoPaq[i].value.costo
                });
            }
            return psicometricos;
        }
    }

    getReclutamiento(){
        if(this.state.showReclutamiento === true && this.state.selReclutamiento.length > 0 && this.state.selGarantia.length > 0){
            let reclutamientos = [];
            this.setState({valid:this.state.valid+1})
            for(let i=0;i<this.state.selReclutamiento.length;i++){
                reclutamientos.push({
                    idReclutamiento: this.state.selReclutamiento[i].value.id,
                    garantia: this.getReclutamientoGarantia(i),
                    honorarios: this.getReclutamientoHonorarios(i)
                })
            }
            return reclutamientos;
        }
    }

    getReclutamientoGarantia(n){
        if(this.state.codeReclutamiento[n] === "otro")
            return document.getElementById("txtGarantia"+n.toString()).value;
        else
            return this.state.selGarantia[n].value.garantia;
    }

    getReclutamientoHonorarios(n){
        if(this.state.honorarios[n].length > 1)
            return this.state.selHonorario[n].value.honorario

        if(this.state.honorarios[n].length === 1 || this.state.codeReclutamiento[n] === "otro")
            return document.getElementById("txtHonorarios"+n.toString()).value;
    }

    getSocioeconimicoCantidad(i){
        if(this.state.selSocioeconimicos[i].value.paquete == 1)
            return this.state.selSocioeconimicos[i].value.paquete
        else
            return document.getElementById("txtSocCantidad"+i.toString()).value;
    }

    getSocioeconimicoCosto(i){
        if(this.state.selSocioeconimicos[i].value.code != "otro")
            return this.state.selSocioeconimicos[i].value.costo
        else
            return document.getElementById("txtSocCosto"+i.toString()).value;
    }

    getVerificacionCantidad(i){
        if(this.state.selVerificaciones[i].value.code != "otro")
            return this.state.selVerificaciones[i].value.cantidad;
        else
            return document.getElementById("txtVerifCant"+i.toString()).value;
    }

    getVerificacionCosto(i){
        if(this.state.selVerificaciones[i].value.code != "otro")
            return this.state.selVerificaciones[i].value.costoUnitario;
        else
            return document.getElementById("txtVerifCosto"+i.toString()).value
    }

    checkService(e,name){
        let ref = 'ref_' + name;
        this.refs[ref].checked = !this.refs[ref].checked;

        if(ref === "ref_check247"){
            this.setState({show247:this.refs[ref].checked})

            if(!this.refs[ref].checked)
                this.setState({totPlataforma:0})
        }

        if(ref === "ref_checkVerificacion"){
            this.setState({showVerifacion:this.refs[ref].checked})

            if(!this.refs[ref].checked)
                this.setState({totVerificacion:0})
        }

        if(ref === "ref_checkSocioeconomico"){
            this.setState({showSocioeconomico:this.refs[ref].checked})

            if(!this.refs[ref].checked)
            this.setState({totSocioeconomico:0})
        }

        if(ref === "ref_checkPsicometrico"){
            this.setState({showPsicometria:this.refs[ref].checked})

            if(!this.refs[ref].checked){
                this.setState({totPsicometria:0})
            }
        }

        if(ref === "ref_checkReclutamiento"){
            this.setState({showReclutamiento:this.refs[ref].checked})
        }
    }

    changePlataforma(e){
        console.log(e);
        this.state.selPlataforma[e.target.name] = e.target;
        this.setState({selPlataforma:this.state.selPlataforma});
        if(e.target.value !== undefined){
            this.state.plataformaCosto[e.target.name] = true;
            this.setState({plataformaCosto:this.state.plataformaCosto});
            this.state.plt247[e.target.name] = e.target.value;
            this.setState({plt247:this.state.plt247})
    }else{
            this.state.plataformaCosto[e.target.name] = false;
            this.setState({plataformaCosto:this.state.plataformaCosto});
        }
    }

    changeTotalPlataforma(e){
        let i = parseInt(e.target.name);
        let selPlataforma = this.state.plt247[i];
        let txtColaboradores = document.getElementById("txtPlataformaColaborador"+e.target.name).value;

        let total = 0;
        if(txtColaboradores != "")
            txtColaboradores = parseFloat(txtColaboradores);
        else
            txtColaboradores = 0;

        
        total = txtColaboradores * this.state.plt247[i].costo;
        
        this.state.totPlataformas[i] = total;
        this.setState({totPlataformas:this.state.totPlataformas});


        let tot= 0;
        for(let i=0;i<this.state.totPlataformas.length;i++){
            tot = tot + this.state.totPlataformas[i];
        }

        if(tot > 0)
            this.setState({totPlataforma:tot});
        else
            this.setState({totPlataforma:0});

    }

    changeVerificacion(e){
        this.state.selVerificaciones[e.target.name] = e.target;
        this.setState({selVerificaciones:this.state.selVerificaciones});

        if(e.target.value.code === 'otro')
            this.state.verificacionCostos[e.target.name] = true;
        else
            this.state.verificacionCostos[e.target.name] = 0;
        
        this.setState({verificacionCostos:this.state.verificacionCostos})

        // this.setState({selVerificacion:e.target})
        // if(e.target.value.code === 'otro')
        //     this.setState({verificacionCosto:true})
        // else
        //     this.setState({verificacionCosto:false})

        this.changeTotalVerificacion(e.target);
    }

    changeTotalVerificacion(selVerificacion){

        if(selVerificacion.value.paquete !== "OTRO")
            this.state.totVerificaciones[selVerificacion.name] = selVerificacion.value.costototal;

        if(selVerificacion.value.paquete === "OTRO")
            this.state.totVerificaciones[selVerificacion.name] = 0;

        this.setState({totVerificaciones:this.state.totVerificaciones});

        
        let tot= 0;
        for(let i=0; i<this.state.totVerificaciones.length;i++){
            tot = tot + this.state.totVerificaciones[i];
        }

        if(tot > 0)
            this.setState({totVerificacion:tot});
        else
            this.setState({totVerificacion:0});
    }

    changeTotalotroVerif(e){
        let i = e.target.name;
        let costo = 0,cantidad=0,total=0;

        cantidad = document.getElementById("txtVerifCant"+i.toString()).value;
        if(cantidad !== "")
            cantidad = parseFloat(cantidad);
        else
            cantidad = 0;

        costo = document.getElementById("txtVerifCosto"+i.toString()).value;
        if(costo !== "")
            costo = parseFloat(costo);
        else
            costo = 0;

        total = costo * cantidad;

        this.state.totVerificaciones[e.target.name] = total;
        this.setState({totVerificaciones:this.state.totVerificaciones});

        let tot= 0;
        for(let i=0; i<this.state.totVerificaciones.length;i++){
            tot = tot + this.state.totVerificaciones[i];
        }

        if(tot > 0)
            this.setState({totVerificacion:tot});
        else
            this.setState({totVerificacion:0});
    }

    changeSocioeconomico(e){
        this.state.selSocioeconimicos[e.target.name] = e.target;
        this.state.selSocioeconimicoPaqs[e.target.name] = e.target.value.paquete;
        this.state.selSocioeconimicoCodes[e.target.name] = e.target.value.paquete;

        this.setState({
            selSocioeconimicos: this.state.selSocioeconimicos,
            selSocioeconimicoPaqs: this.state.selSocioeconimicoPaqs,
            selSocioeconimicoCodes: this.state.selSocioeconimicoCodes
        });

        if(e.target.value.paquete !== null)
            this.state.socioeconomicoCostos[e.target.name] = true;
        else
            this.state.socioeconomicoCostos[e.target.name] = false;

        this.setState({socioeconomicoCostos:this.state.socioeconomicoCostos});

            this.changeTotalSocioeconomico(e)
    }

    changeTotalSocioeconomico(e){
        let i = e.target.name.toString();

        // if(this.state.selSocioeconimicos[e.target.name].value.paquete === "OTRO" && document.getElementById("txtSocCantidad"+i).value !== null){
            let txtCantidad = "";
            let selSocioeconimico = this.state.selSocioeconimicos[e.target.name];
            
            if( (this.state.selSocioeconimicos[e.target.name].value.paquete === "1") 
             || (this.state.selSocioeconimicos[e.target.name].value.paquete !== "1" && document.getElementById("txtSocCantidad"+i) === null)
            //  || (this.state.selSocioeconimicos[e.target.name].value.paquete == "OTRO" && document.getElementById("txtSocCantidad"+i) === null) 
             )
                txtCantidad = 1;
            else
                txtCantidad = document.getElementById("txtSocCantidad"+i).value;

            let total = 0 ;

            if(txtCantidad !== "")
                txtCantidad = parseFloat(txtCantidad);
            else
                txtCantidad = 0;


            let costo = 0;
            if(this.state.selSocioeconimicos[e.target.name].value.paquete !== "OTRO"){

                costo = this.state.selSocioeconimicos[e.target.name].value.costo;

                if(this.state.selSocioeconimicos[e.target.name].value.paquete === "1")
                    txtCantidad = 1;

                total = costo * txtCantidad;
            }
            else{

                let txtcosto = 0;
                if(document.getElementById("txtSocCosto"+i) !== null )
                    txtcosto = document.getElementById("txtSocCosto"+i).value;

                if(txtcosto !== "")
                    txtcosto = parseFloat(txtcosto);
                else
                    txtcosto = 0;

                total = txtcosto * txtCantidad;
            }

            this.state.totSocioeconomicos[e.target.name] = total;
            this.setState({totSocioeconomicos:this.state.totSocioeconomicos});

            let tot = 0;
            for(let i =0;i<this.state.totSocioeconomicos.length; i++){
                tot = tot + this.state.totSocioeconomicos[i];
            }

            if(tot > 0 )
                this.setState({totSocioeconomico:tot});
            else
                this.setState({totSocioeconomico:0});

        // }
        // this.setState({totSocioeconomico:total});
    }

    changePsicometria(e){
        this.state.selPsicometrico[e.target.name] = e.target;
        this.state.totPsicometrias[e.target.name] = 0;
        this.state.selPsicometricoPaq[e.target.name] = undefined;

        this.setState({
            selPsicometrico:this.state.selPsicometrico,
            totPsicometrias: this.state.totPsicometrias,
            selPsicometricoPaq:this.state.selPsicometricoPaq
        });

        if(e.target.value.nombre != null)
            this.psicometrico(e.target.value.nombre,e.target.name);
    }

    async psicometrico(psicometrico,i){
        await axios.get(`${this.url}/api/services/psicometrico?psic=${psicometrico}`)
        .then(response => {
            this.setState({psicometriaCosto:response.data});
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    changePsicometriaPaq(e){
        if(e.target.value !== ""){
            this.state.selPsicometricoPaq[e.target.name] = e.target;
            this.state.totPsicometrias[e.target.name] = e.target.value.costo;
        }else{
            this.state.selPsicometricoPaq[e.target.name] = 0
            this.state.totPsicometrias[e.target.name] = 0;
        }

        this.setState({
            selPsicometricoPaq:this.state.selPsicometricoPaq,
            totPsicometrias:this.state.totPsicometrias
        });

        this.changeTotalPsicometria();
    }

    changeTotalPsicometria(){
        let tot = 0;
        for(let i=0;i<this.state.totPsicometrias.length;i++){
            tot = tot + this.state.totPsicometrias[i];
        }

        if(tot>0){
            this.setState({totPsicometria:tot});
        }else{
            this.setState({totPsicometria:0});
        }
    }

    changeReclutamiento(e){
        this.state.selReclutamiento[e.target.name] = e.target;
        this.state.codeReclutamiento[e.target.name] = e.target.value.code;
        this.state.selGarantia[e.target.name] =[]
        this.state.honorarios[e.target.name] = [];

        this.setState({
            selReclutamiento:this.state.selReclutamiento,
            codeReclutamiento: this.state.codeReclutamiento,
            selGarantia: this.state.selGarantia,
            honorarios: this.state.honorarios
        })

        if(e.target.value.code !== undefined){
            this.state.codeReclutamiento[e.target.name] = e.target.value.code;
            this.setState({codeReclutamiento:this.state.codeReclutamiento});
            this.reclutamiento(e.target.value.code,e.target.name)
    }else{
            this.state.codeReclutamiento[e.target.name] = '';
            this.state.reclutamientoGarantia[e.target.name]=[];
            this.setState({
                codeReclutamiento:this.state.codeReclutamiento,
                reclutamientoGarantia:this.state.reclutamientoGarantia
            })
        }
    }

    changeGarantia(e){
        this.state.selGarantia[e.target.name] = e.target;
        this.state.honorarios[e.target.name] = [];
        
        this.setState({selGarantia:this.state.selGarantia})
        this.setState({honorarios:this.state.honorarios})

        if(e.target.value.code != undefined){
            this.honorarios(e.target.value.code,e.target.name);
        }else{
            this.state.honorarios[e.target.name] = [];
            this.setState({honorarios:this.state.honorarios})
        }
    }

    changeHonorarios(e){
        this.state.selHonorario[e.target.name] = e.target;
        this.setState({selHonorario:this.state.selHonorario});
        // this.setState({selHonorario:e.target})
    }

    changeEstado(e){
        this.setState({selEstado:e.target})
    }

    addReclutamiento(){
        if(this.state.showReclutamiento === true && this.state.showReclutamiento2 === false && this.state.showReclutamiento3===false && this.state.showReclutamiento4 === false){
            this.setState({showReclutamiento2:true});
        }else if(this.state.showReclutamiento === true && this.state.showReclutamiento2 === true && this.state.showReclutamiento3===false && this.state.showReclutamiento4 === false){
            this.setState({showReclutamiento3:true});
        } else if(this.state.showReclutamiento === true && this.state.showReclutamiento2 === true && this.state.showReclutamiento3 === true && this.state.showReclutamiento4 === false){
            this.setState({showReclutamiento4:true});
        }
    }

    createPdf(data){
        const MyDocument = (
            <PdfDocument data={data}></PdfDocument>
        );

        this.setState({pdf:MyDocument,pdfshow:true});
    }

    openpdf(blob,url){
        if(blob !== null && url !== null){
            var file = new Blob([blob], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            console.log(fileURL);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = "propuesta" + this.state.nombrePDF;
            a.click();

            // let email = true;
            if(this.state.email === true){
                this.sendEmail(file)
            }else{
                this.props.history.push('/cotizaciones');
            }
        }
    }

    addP(){
      this.state.nPlataforma.push(this.state.nPlataforma.length+1)
      this.setState({nPlataforma:this.state.nPlataforma}); 
    } 

    removeP(){
        this.state.nPlataforma.pop();
        this.setState({nPlataforma:this.state.nPlataforma}); 
        this.state.totPlataformas.pop();
        this.setState({totPlataformas:this.state.totPlataformas}); 
        this.state.plt247.pop();
        this.setState({plt247:this.state.plt247}); 
        this.state.selPlataforma.pop();
        this.setState({selPlataforma:this.state.selPlataforma}); 
        this.state.plataformaCosto.pop();
        this.setState({plataformaCosto:this.state.plataformaCosto}); 

        
        let tot= 0;
        for(let i=0;i<this.state.totPlataformas.length;i++){
            tot = tot + this.state.totPlataformas[i];
        }

        if(tot > 0)
            this.setState({totPlataforma:tot});
        else
            this.setState({totPlataforma:0});

    }

    addV(){
        this.state.nVerificacion.push(this.state.nVerificacion.length+1)
        this.setState({nVerificacion:this.state.nVerificacion}); 
    } 
  
    removeV(){
          this.state.nVerificacion.pop();
          this.state.selVerificaciones.pop();
          this.state.verificacionCostos.pop();
          this.state.totVerificaciones.pop();
          this.setState({
            nVerificacion: this.state.nVerificacion,
            selVerificaciones: this.state.selVerificaciones,
            verificacionCostos: this.state.verificacionCostos,
            totVerificaciones: this.state.totVerificaciones
        });

        
        let tot= 0;
        for(let i=0; i<this.state.totVerificaciones.length;i++){
            tot = tot + this.state.totVerificaciones[i];
        }

        if(tot > 0)
            this.setState({totVerificacion:tot});
        else
            this.setState({totVerificacion:0});

    }

    addS(){
        this.state.nSocioeconomicos.push(this.state.nSocioeconomicos.length+1)
        this.setState({nSocioeconomicos:this.state.nSocioeconomicos}); 
    } 
  
    removeS(){
          this.state.nSocioeconomicos.pop();
          this.state.selSocioeconimicos.pop();
          this.state.socioeconomicoCostos.pop();
          this.state.totSocioeconomicos.pop();
          this.state.selSocioeconimicoCodes.pop();
          this.state.selSocioeconimicoPaqs.pop();
          this.setState({
            nSocioeconomicos: this.state.nSocioeconomicos,
            selSocioeconimicos: this.state.selSocioeconimicos,
            socioeconomicoCostos: this.state.socioeconomicoCostos,
            totSocioeconomicos: this.state.totSocioeconomicos,
            selSocioeconimicoCodes: this.state.selSocioeconimicoCodes,
            selSocioeconimicoPaqs: this.state.selSocioeconimicoPaqs,
        });

        
        let tot = 0;
        for(let i =0;i<this.state.totSocioeconomicos.length; i++){
            tot = tot + this.state.totSocioeconomicos[i];
        }

        if(tot > 0 )
            this.setState({totSocioeconomico:tot});
        else
            this.setState({totSocioeconomico:0});
    }

    addPS(){
        this.state.nPsicometrias.push(this.state.nPsicometrias.length+1)
        this.setState({nPsicometrias:this.state.nPsicometrias}); 
    }

    removePS(){
        this.state.nPsicometrias.pop();
        this.state.selPsicometrico.pop();
        this.state.selPsicometricoPaq.pop();
        this.state.totPsicometrias.pop();
        this.setState({
          nPsicometrias: this.state.nPsicometrias,
          selPsicometrico: this.state.selPsicometrico,
          selPsicometricoPaq: this.state.selPsicometricoPaq,
          totPsicometrias: this.state.totPsicometrias
      });

      this.changeTotalPsicometria();
    }

    addR(){
        this.state.nReclutamientos.push(this.state.nReclutamientos.length+1);
        this.setState({nReclutamientos:this.state.nReclutamientos});
        this.reclutamientos(this.state.nReclutamientos.length)
    }

    removeR(){
        this.state.nReclutamientos.pop();
        this.state.selReclutamiento.pop();
        this.state.selGarantia.pop();
        this.state.selHonorario.pop();
        this.state.reclutamientos.pop();
        this.state.codeReclutamiento.pop();
        this.state.reclutamientoGarantia.pop();
        this.state.honorarios.pop();

        this.setState({
            nReclutamientos: this.state.nReclutamientos,
            selReclutamiento: this.state.selReclutamiento,
            selGarantia: this.state.selGarantia,
            selHonorario: this.state.selHonorario,
            reclutamientos: this.state.reclutamientos,
            codeReclutamiento: this.state.codeReclutamiento,
            reclutamientoGarantia: this.state.reclutamientoGarantia,
            honorarios: this.state.honorarios
        });
    }

    moneyFormat(Qantity){
        return '$'+(Qantity).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

    render(){
        const plataformas = this.state.nPlataforma.map(data =>{
            return (
                <React.Fragment>
                    <Grid  style = {{marginTop: "20px"}} container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl  variant="outlined" style = {{width: "90%"}}>
                                <InputLabel style={{top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    id={"selPlataforma"+data.toString()}
                                    name={data}
                                    onChange={this.changePlataforma}
                                >
                                <MenuItem value="">
                                    <em>Paquete</em>
                                </MenuItem>
                                {
                                    this.state.plataforma247.map(service => {
                                        return (
                                            <MenuItem value={service} key={data} >{service.paquete}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.plataformaCosto[data] ?
                                <InputLabel style={{marginTop:"10px",marginBottom:"-10px"}}>Colaboradores</InputLabel>
                            :''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginLeft:"-45px"}}>
                        {
                            this.state.plataformaCosto[data] ?
                                <InputLabel style={{marginTop:"10px",marginBottom:"-10px"}}>Costo por colaborador</InputLabel>
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginRight:"-45px"}}>
                        {
                                this.state.plataformaCosto[data] ?
                                    <TextField margin="normal" variant="outlined" style = {{width: "87%"}}
                                        id={"txtPlataformaColaborador"+data.toString()}
                                        name={data}
                                        onChange={this.changeTotalPlataforma}
                                        InputProps={{startAdornment: (
                                            <InputAdornment position="start">
                                                #
                                            </InputAdornment>
                                            ),}}
                                    />
                            : ''
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginRight:"-45px"}}>
                            {
                                this.state.plataformaCosto[data] ?
                                    <TextField margin="normal" variant="outlined" style={{width: "87%",marginRight:"100px"}}
                                        id={"txtPlataformaCosto"+data.toString()}
                                        name={data}
                                        value={this.state.plt247[data].costo}
                                        onChange={this.changeTotalPlataforma}
                                        InputProps={{startAdornment: (
                                            <InputAdornment position="start">
                                                $
                                            </InputAdornment>
                                            ),}}
                                    />
                                : ''

                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            {
                                this.state.totPlataformas[data] > 0 && this.state.show247===true ?
                                    <p style={{color:"#676666"}}>{"Total Plataforma 24/7: "+ this.moneyFormat(this.state.totPlataformas[data])}</p>
                                : ''
                            }
                        </Grid>
                    </Grid>
                </React.Fragment>
              )
        })

        const verificaciones = this.state.nVerificacion.map(data =>{
            return (
                <React.Fragment>
                    <Grid style = {{marginTop: "20px"}} container>
                   
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl  variant="outlined" style = {{width: "90%"}}>
                                <InputLabel style={{marginLeft:'20px',top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    id={"selVerificacion"+data.toString()}
                                    name={data}
                                    onChange={this.changeVerificacion}>
                                <MenuItem value="">
                                    <em>Paquete</em>
                                </MenuItem>
                                {
                                    this.state.verificacion.map(service => {
                                        return (
                                            <MenuItem value={service}>{service.paquete}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.verificacionCostos[data] ?
                            <InputLabel style={{marginLeft:'30px',marginTop:'20px',marginBottom:"-10px"}}>Cantidad</InputLabel>
                        : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.verificacionCostos[data] ?
                            <InputLabel style={{marginLeft:'30px',marginTop:'20px',marginBottom:"-10px"}}>Costo Unitario</InputLabel>
                        : ''
                        }
                        </Grid>
               
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.verificacionCostos[data] ?
                                <TextField  margin="normal" variant="outlined" style = {{width: "95%",marginLeft:'20px'}}
                                    id={"txtVerifCant"+data}
                                    name={data}    
                                    onChange={this.changeTotalotroVerif}
                                    InputProps={{ startAdornment:
                                    <InputAdornment position="start">
                                        #
                                    </InputAdornment>,
                                }}
                                />
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.verificacionCostos[data] ?
                                <TextField margin="normal" variant="outlined" style = {{width: "95%",marginLeft:'20px'}}
                                        id={"txtVerifCosto"+data.toString()}
                                        name={data}
                                        onChange={this.changeTotalotroVerif}
                                        InputProps={{startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                        ),}}
                                />
                            : ''
                        }
                        </Grid>
                       
                        <Grid item xs={12} sm={12} md={12}>
                        {
                            this.state.totVerificaciones[data] > 0 ?
                                <p style={{color:"#676666"}}>{"Total Verificacion Perfil: "+ this.moneyFormat(this.state.totVerificaciones[data])}</p>
                            : ''
                        }
                        </Grid>
                    </Grid>
                </React.Fragment>
              )
        })

        const socioeconomicos = this.state.nSocioeconomicos.map(data =>{
            return (
                <React.Fragment>
                    <Grid style = {{marginTop: "20px"}} container>      
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" style = {{width: "90%",marginLeft:'5px'}}>
                                <InputLabel style={{marginLeft:'20px',top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    id={"selSocioeconomico"+data.toString()}
                                    name={data}
                                    onChange={this.changeSocioeconomico}
                                >
                                <MenuItem value="">
                                    <em>Paquete</em>
                                </MenuItem>
                                {
                                    this.state.socioeconomico.map(service => {
                                        return (
                                            <MenuItem value={service} >{service.paquete}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.socioeconomicoCostos[data] === true ?
                                <InputLabel style={{marginLeft:'10px',marginTop:'10px'}}>Cantidad</InputLabel>
                        : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.socioeconomicoCostos[data] ?
                                <InputLabel style={{marginLeft:'-35px',marginTop:'10px'}}>Costo Unitario</InputLabel>
                        : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginLeft:"5px",marginTop:"-10px"}}>
                        {
                            this.state.socioeconomicoCostos[data] === true && this.state.selSocioeconimicos[data].value.paquete === "1" ?
                                <TextField margin="normal" variant="outlined" style = {{width: "87%"}} 
                                    value="1"
                                    id={"txtSocCantidad"+data.toString()}
                                    name={data}
                                    onLoad={this.changeTotalSocioeconomico}
                                    InputProps={{ startAdornment:
                                        <InputAdornment position="start">
                                            #
                                        </InputAdornment>
                                }}
                                />
                            : this.state.socioeconomicoCostos[data] === true && this.state.selSocioeconimicos[data].value.paquete !== "1" ?
                                <TextField margin="normal" variant="outlined" style = {{width: "87%"}} 
                                id={"txtSocCantidad"+data.toString()}
                                name={data} 
                                onChange={this.changeTotalSocioeconomico}
                                    InputProps={{ startAdornment:
                                        <InputAdornment position="start">
                                            #
                                        </InputAdornment>
                                }}
                                />
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginLeft:"-45px",marginTop:"-10px"}}>
                        {
                            this.state.socioeconomicoCostos[data] === true && this.state.selSocioeconimicos[data].value.paquete !== "OTRO" ?
                                <TextField  margin="normal" variant="outlined" style={{width: "87%",marginRight:"100px"}}
                                    value={this.state.selSocioeconimicos[data].value.costo} 
                                    id={"txtSocCosto"+data.toString()}
                                    name={data}
                                    onChange={this.changeTotalSocioeconomico}
                                    InputProps={{startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                    ),}}
                                />
                                : this.state.socioeconomicoCostos[data] === true && this.state.selSocioeconimicos[data].value.paquete === "OTRO" ?
                                    <TextField margin="normal" variant="outlined" style={{width: "95%",marginLeft:'20px',marginBottom:"-10px" }}
                                        id={"txtSocCosto"+data.toString()}
                                        name={data}    
                                        onChange={this.changeTotalSocioeconomico}
                                        InputProps={{startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                        ),}}
                                    />
                                :''
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                        {
                            this.state.totSocioeconomicos[data] > 0 ?
                                <p style={{color:"#676666",marginLeft:"5px"}}>{"Total Socioeconomico: "+ this.moneyFormat(this.state.totSocioeconomicos[data])}</p>
                            : ''
                        }
                        </Grid>
                    </Grid>
                </React.Fragment>
              )
        })

        const psicometrias = this.state.nPsicometrias.map(data =>{
            return (
                <React.Fragment>
                    <Grid style = {{marginTop: "20px"}} container>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" style={{width: "90%",marginLeft:'5px'}}>
                                <InputLabel style={{marginLeft:'20px',top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    id={"selPsicometrico"+data.toString()}
                                    name={data}
                                    onChange={this.changePsicometria}
                                >
                                    {
                                    this.state.psicometria.map(service => {
                                        return (
                                            <MenuItem value={service}>{service.paquete}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" style={{width: "90%",marginLeft:'5px',marginTop:'10px'}}>
                                <InputLabel style={{marginLeft:'20px',top:"10px"}}>Cantidad</InputLabel>
                                <Select
                                    id={"selPsicometria"+data.toString()}
                                    name={data}
                                    value={this.state.selPsicometricoPaq[data] !== undefined ? this.state.selPsicometricoPaq[data].value : ''}
                                    renderValue={(selected) => {console.log(selected);return(<div>{this.state.selPsicometricoPaq.length > 0 ? this.state.selPsicometricoPaq[data].value.paquete : ''}</div>)}}
                                    onChange={this.changePsicometriaPaq}
                                >
                                    <MenuItem value="">
                                        <em>Paquete</em>
                                    </MenuItem>
                                {
                                    this.state.psicometriaCosto.map(service => {
                                        return (
                                            <MenuItem value={service}>{service.paquete}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                                </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{textAlign:"left"}}>
                        {
                            this.state.selPsicometricoPaq[data] ?
                                <p style={{color:"#676666",marginLeft:"5px"}}>{"Total Psicometria: "+ this.moneyFormat(this.state.selPsicometricoPaq[data].value.costo)}</p>
                            : ''
                        }
                        </Grid>
                    </Grid>
                </React.Fragment>
                )
        });

        const reclutamientos = this.state.nReclutamientos.map(data => {
            return (
                <React.Fragment>
                    <Grid style = {{marginTop: "20px"}} container>
                        <Grid item xs={12} sm={12} md={12}>
                            { 
                                this.state.reclutamientos.length > 1 && this.state.reclutamientos[data] != undefined?
                                <FormControl variant="outlined" style={{width: "90%",marginLeft:'5px'}}>
                                <InputLabel style={{marginLeft:'20px',top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    name={data}
                                    id={"selReclutamiento"+data.toString()}
                                    onChange={this.changeReclutamiento}
                                >
                                    {
                                        this.state.reclutamientos[data].map(service => {
                                            return (
                                                <MenuItem value={service}>{service.paquete}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                                :''
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.reclutamientoGarantia.length > 1 && this.state.reclutamientoGarantia[data] != undefined ?
                            <div>
                                <InputLabel  style={{marginLeft:'20px',marginTop:"15px"}}>Garantia</InputLabel>
                                <FormControl variant="outlined" style={{width: "87%",marginLeft:'5px'}}>
                                {
                                    this.state.codeReclutamiento[data] === "otro" ?
                                        <TextField margin="normal" variant="outlined" style = {{width: "100%",marginTop:"-1px"}}
                                                id={"txtGarantia"+data.toString()} 
                                                name={data}/>
                                :
                                        <Select id={"selGarantia"+data.toString()}
                                            name={data}
                                            onChange={this.changeGarantia}
                                        >
                                        {
                                            this.state.reclutamientoGarantia[data].map(service => {
                                                    return(
                                                        <MenuItem value={service}>{service.garantia}</MenuItem>
                                                    )
                                            })
                                        }
                                        </Select>
                                }
                                </FormControl>
                            </div>
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginLeft:"-30px"}}>
                        {
                            this.state.reclutamientoGarantia.length > 1 && this.state.reclutamientoGarantia[data] != undefined  ?
                            <div>
                                <InputLabel id="demo-simple-select-outlined-label" style={{marginTop:"10px"}}>Honorarios</InputLabel>
                                <FormControl variant="outlined" style={{width: "87%",marginLeft:'-30px',marginTop:"-10px"}}>
                                {
                                    this.state.codeReclutamiento[data] === "otro" ?
                                        <TextField margin="normal" variant="outlined" style = {{width: "100%",marginLeft:'20px',marginTop:"15px"}}
                                            id={"txtHonorarios"+data.toString()} 
                                            name={data}
                                        />
                                    :this.state.honorarios[data].length === 1 ?
                                        <TextField  margin="normal" variant="outlined" style = {{width: "100%",marginLeft:'20px'}}
                                            value={this.state.honorarios[data][0].honorario}
                                            id={"txtHonorarios"+data.toString()} 
                                            name={data}
                                        />
                                    :this.state.honorarios[data].length > 1 ?
                                            <Select 
                                                style={{marginTop:"15px",marginLeft:"15px",width:"100%"}}
                                                id={"selHonorarios"+data.toString()} 
                                                name={data}
                                                onChange={this.changeHonorarios}
                                            >
                                            {
                                                this.state.honorarios[data].map(service => {
                                                        return (
                                                        <MenuItem value={service}>{service.honorario}</MenuItem>
                                                    )
                                                })
                                            }
                                            </Select>
                                        :''
                                }
                                </FormControl>
                            </div>
                            :''
                        }
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        })

        return(
            <div style={{backgroundColor:"FBFBFB"}}>
            <Routes/>
            <div id={"content"}>

                {/* titulo */}
                <Grid style={{display:"flex",marginLeft:"10px",paddingTop:"50px"}} container>
                    <Grid item xs={12} sm={1} md={1}>
                        <img src="group-users.svg" width="60%" height="60%" style={{marginTop:"10px",paddingLeft:"50px"}}></img>
                    </Grid>
                    <Grid item xs={12} sm={11} md={11}>
                        <p style={{color:"#0f3b6e",fontSize:"25px",fontWeight:"bold",paddingLeft:"25px"}} >{this.state.cliente}</p>
                    </Grid>
                </Grid>

                <Grid style = {{marginTop: "20px",marginLeft:"40px"}} container>
                    {/* {
                        this.state.idcli > 0 ?
                            <Grid item xs={12} sm={5} md={5}  style={{textAlign:"center"}}>
                                <p style={{color:"#183956", fontSize:"25px"}} >{this.state.cliente}</p>
                            </Grid>
                        :''
                    } */}
                
                                    {/* {element} */}
                <Grid item xs={12} sm={5} md={5}></Grid>
                    <Grid item xs={12}>
                       <p style={{color:"#0f3b6e",fontSize:"22px",marginLeft:'20px'}}>Servicio / Producto</p>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} style={{color:"#676666"}}> */}
                    <Grid item xs={12} sm={6} md={6} style={{color:"#676666"}}>
                        <FormControlLabel control={
                            <Checkbox
                                ref={'ref_check247'}
                                value="checked"
                                color="primary"
                                className="chckpaq"
                                id="check247"
                                style={{marginLeft:'20px'}}
                                onClick ={(e)=> this.checkService(e,'check247')}
                                inputProps={{
                                'aria-label': 'secondary checkbox',
                                }}
                            />} label="Plataforma 24/7"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{color:"#676666"}}>
                        <FormControlLabel control={
                            <Checkbox
                                ref={'ref_checkSocioeconomico'}
                                value="checked"
                                id="checksoc"
                                color="primary"
                                style={{marginLeft:'20px'}}
                                onClick ={(e)=> this.checkService(e,'checkSocioeconomico')}
                                inputProps={{
                                'aria-label': 'secondary checkbox',
                                }}
                            />} label="Socioeconomicos"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{color:"#676666"}}>
                        <FormControlLabel control={
                            <Checkbox
                                ref={'ref_checkVerificacion'}
                                value="checked"
                                id={"checkVer"}
                                color="primary"
                                style={{marginLeft:'20px'}}
                                onClick ={(e)=> this.checkService(e,'checkVerificacion')}
                                inputProps={{
                                'aria-label': 'secondary checkbox',
                                }}
                            />} label="Verificacion de Perfil"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{color:"#676666"}}>
                        <FormControlLabel control={
                            <Checkbox
                                ref={'ref_checkPsicometrico'}
                                value="checked"
                                id={"checkpsic"}
                                color="primary"
                                style={{marginLeft:'20px'}}
                                onClick ={(e)=> this.checkService(e,'checkPsicometrico')}
                                inputProps={{
                                'aria-label': 'secondary checkbox',
                                }}
                            />} label="Evaluación Psicometrica"/>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{color:"#676666"}}>
                        <FormControlLabel control={
                            <Checkbox
                            ref={'ref_checkReclutamiento'}
                            value="checked"
                            id="checkrec"
                            color="primary"
                            style={{marginLeft:'20px'}}
                            onClick ={(e)=> this.checkService(e,'checkReclutamiento')}
                                inputProps={{
                                'aria-label': 'secondary checkbox',
                                }}
                            />} label="Reclutamiento de Personal"/>
                    </Grid>
                </Grid>

                {/* <div> */}
                    {/* Plataforma 24/7 */}
                    <Grid style={{display: this.state.show247===true ? 'flex' : 'none',marginLeft:'65px'}}  container>
                        <Grid item xs={12} sm={12} md={12} >
                            <p style={{color:"#0f3b6e", fontSize:"22px"}} > Plataforma 24/7</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" style = {{width: "90%"}}>
                                <InputLabel style={{top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    id="selPlataforma0"
                                    name={0}
                                    onChange={this.changePlataforma}
                                >
                                <MenuItem value="">
                                    <em>Paquete</em>
                                </MenuItem>
                                {
                                    this.state.plataforma247.map(service => {
                                        return (
                                            <MenuItem value={service} key={0} >{service.paquete}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.plataformaCosto[0] ?
                                <InputLabel style={{marginTop:"10px",marginBottom:"-10px"}}>Colaboradores</InputLabel>
                            :''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginLeft:"-45px"}}>
                        {
                            this.state.plataformaCosto[0] ?
                                <InputLabel style={{marginTop:"10px",marginBottom:"-10px"}}>Costo por colaborador</InputLabel>
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginRight:"-45px"}}>
                        {
                                this.state.plataformaCosto[0] ?
                                    <TextField margin="normal" variant="outlined" style = {{width: "87%"}}
                                        id="txtPlataformaColaborador0"
                                        name={0}
                                        onChange={this.changeTotalPlataforma}
                                        InputProps={{startAdornment: (
                                            <InputAdornment position="start">
                                                #
                                            </InputAdornment>
                                            ),}}
                                    />
                            : ''
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            {
                                this.state.plataformaCosto[0] ?
                                    <TextField  margin="normal" variant="outlined" style={{width: "87%",marginRight:"100px"}}
                                        id="txtPlataformaCosto0"
                                        name={0}
                                        value={this.state.plt247[0].costo}
                                        onChange={this.changeTotalPlataforma}
                                        InputProps={{startAdornment: (
                                            <InputAdornment position="start">
                                                $
                                            </InputAdornment>
                                            ),}}
                                    />
                                : ''

                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            {
                                this.state.totPlataformas[0] > 0 && this.state.show247===true ?
                                    <p style={{color:"#676666"}}>{"Total Plataforma 24/7: "+ this.moneyFormat(this.state.totPlataformas[0])}</p>
                                : ''
                            }
                        </Grid>
                        {plataformas}
                        <Grid item xs={12} sm={6} md={6} >
                            <Button style={{color:"#42BF65",marginLeft:"75%"}}
                                onClick={()=>this.addP()}
                                >+ Agregar
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Button style={{color:"#676666",marginLeft:"-5%"}}
                                onClick={()=>this.removeP()}
                                >- Eliminar
                            </Button>
                        </Grid>

                    </Grid>
                    
                    {/* Verificacion de Perfil */}
                    <Grid style={{marginTop:"30px",marginLeft:'65px',display: this.state.showVerifacion===true ? 'flex' : 'none'}} container>
                        <Grid item xs={12} sm={12} md={12} >
                            <p style={{color:"#0f3b6e", fontSize:"22px"}}>Verificacion de Perfil</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" style = {{width: "90%"}}>
                                <InputLabel style={{marginLeft:'20px',top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    id="selVerificacion0"
                                    name={0}
                                    onChange={this.changeVerificacion}
                                >
                                <MenuItem value="">
                                    <em>Paquete</em>
                                </MenuItem>
                                {
                                    this.state.verificacion.map(service => {
                                        return (
                                            <MenuItem value={service}>{service.paquete}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.verificacionCostos[0] ?
                            <InputLabel style={{marginLeft:'30px',marginTop:'20px',marginBottom:"-10px"}}>Cantidad</InputLabel>
                        : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.verificacionCostos[0] ?
                            <InputLabel style={{marginLeft:'30px',marginTop:'20px',marginBottom:"-10px"}}>Costo Unitario</InputLabel>
                        : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.verificacionCostos[0] ?
                                <TextField  margin="normal" variant="outlined" style = {{width: "95%",marginLeft:'20px'}}
                                    id="txtVerifCant0"
                                    name={0}    
                                    onChange={this.changeTotalotroVerif}
                                    InputProps={{ startAdornment:
                                    <InputAdornment position="start">
                                        #
                                    </InputAdornment>,
                                }}
                                />
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.verificacionCostos[0] ?
                                <TextField  margin="normal" variant="outlined" style = {{width: "95%",marginLeft:'20px'}}
                                        id="txtVerifCosto0"
                                        name={0}
                                        onChange={this.changeTotalotroVerif}
                                        InputProps={{startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                        ),}}
                                />
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                        {
                            this.state.totVerificaciones[0] > 0 ?
                                <p style={{color:"#676666"}}>{"Total Verificacion Perfil: "+ this.moneyFormat(this.state.totVerificaciones[0])}</p>
                            : ''
                        }
                        </Grid>
                        {verificaciones}
                        <Grid item item xs={12} sm={6} md={6} >
                            <Button style={{color:"#42BF65",marginLeft:"75%"}}
                                onClick={()=>this.addV()}
                                >+ Agregar
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Button style={{color:"#676666",marginLeft:"-5%"}}
                                onClick={()=>this.removeV()}
                                >- Eliminar
                            </Button>
                        </Grid>
                    </Grid>

                    {/* Estudio Socioeconomico */}
                    <Grid style={{marginTop:"30px",marginLeft:'65px',display: this.state.showSocioeconomico===true ? 'flex' : 'none'}} container>
                        <Grid item xs={12} sm={12} md={12} >
                            <p style={{color:"#0f3b6e", fontSize:"22px"}}>Estudio Socioeconomico</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControl variant="outlined" style = {{width: "90%",marginLeft:'5px'}}>
                                <InputLabel style={{marginLeft:'20px',top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    id="selSocioeconomico0"
                                    name={0}
                                    onChange={this.changeSocioeconomico}
                                >
                                <MenuItem value="">
                                    <em>Paquete</em>
                                </MenuItem>
                                {
                                    this.state.socioeconomico.map(service => {
                                        return (
                                            <MenuItem value={service} >{service.paquete}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.socioeconomicoCostos[0] === true ?
                                <InputLabel style={{marginLeft:'10px',marginTop:'10px'}}>Cantidad</InputLabel>
                        : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.socioeconomicoCostos[0] ?
                                <InputLabel style={{marginLeft:'-35px',marginTop:'10px'}}>Costo Unitario</InputLabel>
                        : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginLeft:"5px",marginTop:"-10px"}}>
                        {
                            this.state.socioeconomicoCostos[0] === true && this.state.selSocioeconimicos[0].value.paquete === "1" ?
                                <TextField margin="normal" variant="outlined" style = {{width: "87%"}} 
                                    value="1"
                                    id="txtSocCantidad0"
                                    name={0}
                                    onLoad={this.changeTotalSocioeconomico}
                                    InputProps={{ startAdornment:
                                        <InputAdornment position="start">
                                            #
                                        </InputAdornment>
                                }}
                                />
                            : this.state.socioeconomicoCostos[0] === true && this.state.selSocioeconimicos[0].value.paquete !== "1" ?
                                <TextField  margin="normal" variant="outlined" style = {{width: "87%"}} 
                                id="txtSocCantidad0"
                                name={0} 
                                onChange={this.changeTotalSocioeconomico}
                                    InputProps={{ startAdornment:
                                        <InputAdornment position="start">
                                            #
                                        </InputAdornment>
                                }}
                                />
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginLeft:"-45px",marginTop:"-10px"}}>
                        {
                            this.state.socioeconomicoCostos[0] === true && this.state.selSocioeconimicos[0].value.paquete !== "OTRO" ?
                                <TextField margin="normal" variant="outlined" style={{width: "87%",marginRight:"100px"}}
                                    value={this.state.selSocioeconimicos[0].value.costo} 
                                    id="txtSocCosto0"
                                    name={0}
                                    onChange={this.changeTotalSocioeconomico}
                                    InputProps={{startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                    ),}}
                                />
                                : this.state.socioeconomicoCostos[0] === true && this.state.selSocioeconimicos[0].value.paquete === "OTRO" ?
                                    <TextField margin="normal" variant="outlined" style={{width: "87%",marginRight:"100px"}}
                                        id="txtSocCosto0"
                                        name={0}    
                                        onChange={this.changeTotalSocioeconomico}
                                        InputProps={{startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                        ),}}
                                    />
                                :''
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                        {
                            this.state.totSocioeconomicos[0] > 0 ?
                                <p style={{color:"#676666",marginLeft:"5px"}}>{"Total Socioeconomico: "+ this.moneyFormat(this.state.totSocioeconomicos[0])}</p>
                            : ''
                        }
                        </Grid>
                        {socioeconomicos}
                        <Grid item xs={12} sm={6} md={6}>
                            <Button style={{color:"#42BF65",marginLeft:"75%"}}
                                onClick={()=>this.addS()}
                                >+ Agregar
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Button style={{color:"#676666",marginLeft:"-5%"}}
                                onClick={()=>this.removeS()}
                                >- Eliminar
                            </Button>
                        </Grid>
                    </Grid>

                    {/* Evaluación Psicometrica */}
                    <Grid id="gridPsicometrico1" className="gridPsicometrico gridPsicometrico1" style={{marginTop:"20px",marginLeft:'65px',display: this.state.showPsicometria===true ? 'flex' : 'none'}} container>
                        <Grid  item xs={12} sm={12} md={12} >
                            <p style={{color:"#0f3b6e", fontSize:"22px"}}>Evaluación Psicometrica</p>
                        </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl variant="outlined" style={{width: "90%",marginLeft:'5px'}}>
                                    <InputLabel style={{marginLeft:'20px',top:"10px"}}>Paquete</InputLabel>
                                    <Select
                                        id="selPsicometrico0"
                                        name={0}
                                        onChange={this.changePsicometria}
                                    >
                                        {
                                        this.state.psicometria.map(service => {
                                            return (
                                                <MenuItem value={service}>{service.paquete}</MenuItem>
                                            )
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl variant="outlined" style={{width: "90%",marginLeft:'5px',marginTop:'10px'}}>
                                    <InputLabel style={{marginLeft:'20px',top:"10px"}}>Cantidad</InputLabel>
                                    <Select
                                        id="selPsicometria0"
                                        name={0}
                                        value={this.state.selPsicometricoPaq[0] !== undefined ? this.state.selPsicometricoPaq[0].value : ''}
                                        renderValue={(selected) => {console.log(selected);return(<div>{this.state.selPsicometricoPaq.length > 0 ? this.state.selPsicometricoPaq[0].value.paquete : ''}</div>)}}
                                        onChange={this.changePsicometriaPaq}
                                    >
                                        <MenuItem value="">
                                            <em>Paquete</em>
                                        </MenuItem>
                                        {
                                            this.state.psicometriaCosto.map(service => {
                                                return (
                                                    <MenuItem value={service}>{service.paquete}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{textAlign:"left"}}>
                            {
                                this.state.selPsicometricoPaq[0] ?
                                    <p style={{color:"#676666",marginLeft:"5px"}}>{"Total Psicometria: "+ this.moneyFormat(this.state.selPsicometricoPaq[0].value.costo)}</p>
                                : ''
                            }
                            </Grid>
                            {psicometrias}
                            <Grid item xs={12} sm={6} md={6} >
                                <Button style={{color:"#42BF65",marginLeft:"75%"}}
                                    onClick={()=>this.addPS()}
                                    >+ Agregar
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                                <Button style={{color:"#676666",marginLeft:"-5%"}}
                                    onClick={()=>this.removePS()}
                                    >- Eliminar
                                </Button>
                            </Grid>
                        </Grid>

                    {/* Reclutamiento Personal */}
                    <Grid id="gridRec1" className="gridRec gridRec1" style={{marginTop:"20px",marginLeft:'65px',display: this.state.showReclutamiento===true ? 'flex' : 'none'}} container>
                        <Grid item xs={12} >
                        <p style={{color:"#0f3b6e", fontSize:"22px"}}>Reclutamiento</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            { 
                                this.state.reclutamientos.length > 0 ?
                                <FormControl variant="outlined" style={{width: "90%",marginLeft:'5px'}}>
                                <InputLabel style={{marginLeft:'20px',top:"10px"}}>Paquete</InputLabel>
                                <Select
                                    name={0}
                                    id="selReclutamiento0"
                                    onChange={this.changeReclutamiento}
                                >
                                    {
                                        this.state.reclutamientos[0].map(service => {
                                            return (
                                                <MenuItem value={service}>{service.paquete}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                                :''
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                        {
                            this.state.codeReclutamiento[0] !== '' && this.state.reclutamientoGarantia.length > 0?
                            <div>
                                <InputLabel style={{marginLeft:'20px',marginTop:"15px"}}>Garantia</InputLabel>
                                <FormControl variant="outlined" style={{width: "87%",marginLeft:'5px'}}>
                                {
                                    this.state.codeReclutamiento[0] === "otro" ?
                                        <TextField margin="normal" variant="outlined" style = {{width: "100%",marginTop:"-1px"}}
                                                id="txtGarantia0" 
                                                name={0}/>
                                :
                                        <Select id="selGarantia0"
                                            name={0}
                                            onChange={this.changeGarantia}
                                        >
                                        {
                                            this.state.reclutamientoGarantia[0].map(service => {
                                                    return(
                                                        <MenuItem value={service}>{service.garantia}</MenuItem>
                                                    )
                                            })
                                        }
                                        </Select>
                                }
                                </FormControl>
                            </div>
                            : ''
                        }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{marginLeft:"-30px"}}>
                        {
                            this.state.honorarios.length > 0  ?
                            <div>
                                <InputLabel id="demo-simple-select-outlined-label" style={{marginTop:"10px"}}>Honorarios</InputLabel>
                                <FormControl variant="outlined" style={{width: "87%",marginLeft:'-30px',marginTop:"-10px"}}>
                                {
                                    this.state.codeReclutamiento[0] === "otro" ?
                                        <TextField margin="normal" variant="outlined" style = {{width: "100%",marginLeft:'20px',marginTop:"15px"}}
                                            id="txtHonorarios0" 
                                            name={0}
                                        />
                                    :this.state.honorarios[0].length === 1 ?
                                        <TextField margin="normal" variant="outlined" style = {{width: "100%",marginLeft:'20px'}}
                                            value={this.state.honorarios[0][0].honorario}
                                            id="txtHonorarios0" 
                                            name={0}
                                        />
                                    :this.state.honorarios[0].length > 1 ?
                                            <Select 
                                                id="selHonorarios0" 
                                                name={0}
                                                style={{marginTop:"15px",marginLeft:"15px",width:"100%"}}
                                                onChange={this.changeHonorarios}
                                            >
                                            {
                                                this.state.honorarios[0].map(service => {
                                                        return (
                                                        <MenuItem value={service}>{service.honorario}</MenuItem>
                                                    )
                                                })
                                            }
                                            </Select>
                                        :''
                                }
                                </FormControl>
                            </div>
                            :''
                        }
                        </Grid>
                        {reclutamientos}
                            <Grid item xs={12} sm={6} md={6} >
                                <Button style={{color:"#42BF65",marginLeft:"75%"}}
                                    onClick={()=>this.addR()}
                                    >+ Agregar
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Button style={{color:"#676666",marginLeft:"-7%"}}
                                    onClick={()=>this.removeR()}
                                    >- Eliminar
                                </Button>
                            </Grid>
                    </Grid>
            {/* </div> */}

                {/* totales */}
                <Grid style={{textAlign:"center",marginTop:"100px"}} container>
                    <Grid item xs={12}>
                    {
                            // this.state.totPlataforma > 0 ?
                            this.state.totPlataforma > 0 && this.state.show247===true ?
                            <div>
                            {
                                <p style={{color:"#676666"}}>{"Total Plataforma 24/7: "+ this.moneyFormat(this.state.totPlataforma)}</p> 
                            }
                            </div>
                            : ''
                    }
                    {
                        this.state.totVerificacion > 0 && this.state.showVerifacion?
                        <div>
                        {
                            <p style={{color:"#676666"}}>{"Total Verificacion Perfil: "+ this.moneyFormat(this.state.totVerificacion)}</p> 
                        }
                        </div>
                        : ''
                    }
                    {
                        this.state.totSocioeconomico > 0 && this.state.showSocioeconomico?
                        <div>
                        {
                            <p style={{color:"#676666"}}>{"Total Socioeconomico: "+ this.moneyFormat(this.state.totSocioeconomico)}</p> 
                        }
                        </div>
                        : ''
                    }
                    {
                        this.state.totPsicometria > 0 && this.state.showPsicometria===true ?
                            <p style={{color:"#676666"}}>{"Total Psicometria: "+ this.moneyFormat(this.state.totPsicometria)}</p> 
                        : ''
                    }
                    {
                        this.state.totPlataforma > 0 || this.state.totVerificacion > 0 || this.state.totSocioeconomico > 0 || this.state.totPsicometria > 0 ?
                        <p style={{color:"#676666"}}>Total: {this.moneyFormat(this.state.totPlataforma + this.state.totVerificacion + this.state.totSocioeconomico + this.state.totPsicometria)}</p> 
                        : ''
                    }
                    </Grid>
                </Grid>

                {/* Generar PDF y enviar Email */}
                <Grid style={{textAlign:"center",marginTop:"60px"}} container>
                    <Grid item xs={12} sm={3} md={3}>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            onClick={()=>this.validate(true)}
                            // onClick={()=>this.pdf()}
                            variant="contained"
                            style={{width:"95%",height:"130%",backgroundColor:"#164771",color:"white",marginLeft:'20px'}}>
                            Descargar
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                        <Button
                            onClick={()=>this.validate(false)}
                            variant="contained"
                            style={{width:"95%",height:"130%",backgroundColor:"#164771",color:"white",marginLeft:'20px'}}>
                            Guardar
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                    </Grid>
                </Grid>

                {/* <Pdf/> */}
                <Grid style={{textAlign:"center",marginTop:"30px"}} container>
                    <Grid item xs={12} >
                        {
                            this.state.pdfshow ?
                            <div>
                                 <PDFDownloadLink id="btnpdf" document={this.state.pdf} fileName={"propuesta "+this.state.nombrePDF+".pdf"} style={{display:"none"}}>
                                    {({ blob, url, loading, error }) => (this.openpdf(blob,url))}
                                    {/* {this.sendEmail(callback)} */}
                                </PDFDownloadLink>
                                {/* <BlobProvider document={this.state.pdf} >
                                {({ blob,url }) => (
                                    url != null ?
                                    this.openpdf(blob,url)

                                    : url= ""
                                )}
                                </BlobProvider> */}
                            </div>
                        :''
                        }
                    </Grid>
                </Grid>
                
                {/* GUARDAR DIALOG */}
                <Fragment>
                        <Dialog
                        open={this.state.open}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">Cotizacion #{this.state.nombrePDF}</DialogTitle>
                        {/* <DialogTitle id="alert-dialog-title">Cotizacion #{this.state.idCotizacion.toString()}</DialogTitle> */}
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                ¿ Desea Enviar el archivo por correo al cliente ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color="primary"
                                onClick={()=> this.add(this.state.cotizacion,true,true)}
                                // onClick={()=> this.add(this.state.cotizacion,true)}
                            >
                                Descargar y Enviar
                            </Button>
                            <Button
                                color="danger"
                                onClick={()=> this.add(this.state.cotizacion,false,true)}
                                // onClick={()=> this.add(this.state.cotizacion,false)}
                            >
                                Descargar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </Fragment>
                    </div>
            </div>
        )
    }
}

export default Cotizador;