import React from 'react';
import { environment } from '../config/config'
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Authentication } from '../services/authentication';

class Login extends React.Component{
    
    url = environment.url;
    constructor(props){
        super(props);

        // Authentication.logout();
        if (Authentication.isLogin()) { 
            this.props.history.push('/clientes');
        }
        
        // if (!Authentication.isLogin()) { 
        //     this.props.history.push('/');
        // }

        this.state = {
            username:'',
            password:'',
            msgError: '',
            user:{}
        }
    }

    //  onClickEntrar = props =>()=>{
        onClickEntrar(){
        const usr = this.getObjUser();
        if(usr.username === "" || usr.password === "")
            this.setState({msgError:"Datos incorrectos, favor de completar información"})
        else{
            this.setState({msgError:""})
            Authentication.login(usr)
            .then(result => {
                if(localStorage.currentUser === undefined)
                    this.setState({msgError:"Datos incorrectos, favor de completar información"})
                else
                    this.props.history.push("/clientes");
                // console.log(result);
        });
    }
}

    getObjUser() {
        return {
            username: document.getElementById("txtUsername").value,
            password: document.getElementById("txtPassword").value
        }
    }

    createSession(data){
        if(data.success === true)
            this.setState({msgError:'success'});
        else
          this.setState({msgError:data.msg});
    }

    //crear usuario
    async login(){
        const usr = this.getObjUser();
        await axios.post(`${this.url}/api/auth/signup`,{usr}) //crear usuario
        // await axios.post(`${this.url}/api/auth/login`,{usr})
        .then(response => { 
            console.log(response);
            this.createSession(response.data);
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    render(){
        return(
            <div style={{zIndex:"2"}}>
                <img src="img_bg.png" id="imglogin" style = {{width:"100%",height:"100%"}}/>
                <Grid style = {{position: "relative",width:"100%",height:"400px",marginTop:"-60%"}} container>
                    <Grid item xs={1} sm={1} md={1}>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} style={{border:".5px solid #979797",borderTopLeftRadius:"5px",borderBottomLeftRadius:"5px",}}>
                        <img src="login.png" style = {{width:"100%",height:"100%",borderTopLeftRadius:"5px",borderBottomLeftRadius:"5px",}}/>
                    </Grid>
                    <Grid item xs={5} sm={5} md={5} style={{border:".5px solid #979797",borderTopRightRadius:"5px",borderBottomRightRadius:"5px",backgroundColor:"#FFFFFF",textAlign:"center"}}>
                        <Grid item xs={12} sm={12} md={12} style={{marginBottom:"-20px",marginTop:"20%"}}>
                            <p style={{fontSize:"20px",fontWeight:"bold",color:"#646464"}}>INICIAR SESIÓN</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField id="txtUsername" margin="normal" variant="outlined"
                               placeholder="Email"
                               style = {{width: "80%"}}
                                InputProps={{startAdornment: (
                                <InputAdornment position="start" style={{color:"#646464"}}>
                                  <PermIdentityIcon></PermIdentityIcon>
                                </InputAdornment>
                                ),}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField type="password" id="txtPassword" margin="normal" variant="outlined"  
                               placeholder="Contraseña"
                               style = {{width: "80%"}}
                                InputProps={{startAdornment: (
                                    <InputAdornment position="start" style={{color:"#646464"}}>
                                      <LockOutlinedIcon></LockOutlinedIcon>
                                    </InputAdornment>
                                    ),}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{textAlign:"center",marginTop:"20px"}}>
                        <Button 
                            // onClick={()=>this.login()}  
                            onClick={()=>this.onClickEntrar()}  
                            style={{width:"80%",height:"130%",backgroundColor:"#164771",color:"white"}}>
                            Entrar
                        </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{textAlign:"center",marginTop:"30px",color:"red"}}>
                            {
                                this.state.msgError !== '' ?
                                    this.state.msgError
                                : '' 
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1}>
                    </Grid>
                </Grid>                
            </div>
        )
    }
}

export default Login;
