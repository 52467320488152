import React, { Component, Fragment } from 'react';
import { environment } from '../config/config'
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import MenuItem from '@material-ui/core/MenuItem';
// import Option from '@material-ui/core/Option';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Routes from '../Routes'
import $ from 'jquery';
import { Authentication } from '../services/authentication';

class Cliente extends Component{
    url = environment.url;

    constructor(props){
        super(props);

        if (!Authentication.isLogin()) {
            this.props.history.push('/');
        }

        this.state = {
            estados:[],
            idEstado:"",
            estado:"",
            representante:props.representante,
            puesto:props.puesto,
            telefono:props.telefono,
            extension:props.extension,
            celular:props.celular,
            domicilio:props.domicilio,
            ciudad:props.ciudad,
            email:props.email,
            celular:props.celular,
        }

        this.changeEstado = this.changeEstado.bind(this);
        this.addClientData = this.addClientData.bind(this);
        this.getEstados();
    }


    changeEstado(e){
        this.setState({idEstado:e.target.value});
    }

    addClientData(e){
        let usr = JSON.parse(localStorage.getItem('currentUser'));

        
        let cliente = {
            cliente:document.getElementById("txtCliente").value,
            representante:document.getElementById("txtRepresentante").value,
            puesto:document.getElementById("txtPuesto").value,
            telefono:document.getElementById("txtTelefono").value,
            extension:document.getElementById("txtExt").value,
            celular:document.getElementById("txtCelular").value,
            domicilio:document.getElementById("txtDomicilio").value,
            ciudad:document.getElementById("txtCiudad").value,
            idEstado:this.state.idEstado,
            email:document.getElementById("txtEmail").value,
            idUsuario: usr.user.idusuario,
            idAsesor:usr.user.idasesor
        }
        console.log(cliente);
        this.setClientData(cliente);
    }

    async setClientData(client){
        await axios.post(`${this.url}/api/clientes/add`,{client})
        .then(response => {
            this.props.history.push('/clientes');
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async getEstados(){
        document.getElementById('root').style.marginLeft = "0px";

        await axios.get(`${this.url}/api/cotizador/estados`)
        .then(result => {
            this.setState({estados:result.data});
        })
    }

    render(){
        return(
            <div>
                <Routes/>
                <div id={"content"}>
                    
                    {/* Titulo */}
                    <Grid style={{width:"90%",display:"flex",marginLeft:"40px"}} container>
                        <Grid item xs={12} sm={1} md={1}>
                            <img src="group-users.svg" width="60%" height="60%" style={{marginTop:"20px",paddingLeft:"50px"}}></img>
                        </Grid>
                        <Grid item xs={12} sm={11} md={11}>
                            <p style={{color:"#0f3b6e",fontSize:"25px",fontWeight:"bold",marginTop:"40px",marginLeft:"10px"}} >Nuevo Cliente</p>
                        </Grid>
                    </Grid>

                    {/* FORMULARIO */}
                    <Grid style = {{width:"92.5%",marginTop: "-20px",marginLeft:"70px"}} container>
                        <Grid item xs={12} sm={9} md={9}>
                                <InputLabel id="lblRepresentante" style={{marginLeft:'20px',marginTop:'30px'}}>Cliente / Empresa</InputLabel>
                                <TextField id="txtCliente" name="cliente" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}} />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Button
                                style={{width:"92%",height:"55%",backgroundColor:"#164771",color:"white",marginLeft:'20px',marginTop:"45px"}}
                                onClick={this.addClientData}
                            >
                                Agregar
                            </Button>
                        </Grid>
                    </Grid>
                    
                    <Grid style = {{width:"92.5%",marginTop: "30px",marginLeft:"70px",marginBottom:"-20px"}} container>
                            <Grid item xs={12} sm={7} md={7}>
                                <InputLabel id="lblRepresentante" style={{marginLeft:'20px'}}>Representante Empresa</InputLabel>
                                <TextField id="txtRepresentante" name="representante" variant="outlined"  style = {{width: "95%",marginLeft:'20px',marginTop:"15px"}} />
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <InputLabel id="lblPuesto" style={{marginLeft:'20px'}}>Cargo / Puesto</InputLabel>
                                <TextField id="txtPuesto" name="puesto" margin="normal" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}}  />
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <InputLabel id="lblTelefono" style={{marginLeft:'20px'}}>Telefono</InputLabel>
                                <TextField id="txtTelefono" name="telefono" margin="normal" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}} />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2}>
                                <InputLabel style={{marginLeft:'20px'}}>Ext.</InputLabel>
                                <TextField id="txtExt" name="extension" margin="normal" variant="outlined"  style = {{width: "92%"}} />
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <InputLabel style={{marginLeft:'20px'}}>Celular</InputLabel>
                                <TextField id="txtCelular" name="celular" margin="normal" variant="outlined" style = {{width: "95%",marginLeft:'20px'}}/>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7}>
                                <InputLabel id="lblDomicilio" style={{marginLeft:'20px'}}>Domicilio</InputLabel>
                                <TextField id="txtDomicilio" name="domicilio" margin="normal" variant="outlined" style = {{width: "95%",marginLeft:'20px'}}/>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <InputLabel id="lblCiudad" style={{marginLeft:'20px'}}>Ciudad</InputLabel>
                                <TextField id="txtCiudad" name="ciudad"  margin="normal" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}}  />
                            </Grid>
                            <Grid item xs={12} sm={7} md={7}>
                                <FormControl variant="outlined" style = {{width: "95%",marginLeft:'20px',top:'31px'}}>
                                <InputLabel id="lblEstado" style={{marginLeft:'20px',top:"10px"}}>Estado</InputLabel>
                                    <Select
                                        id="selEstado"
                                        onChange={this.changeEstado}
                                    >
                                    {
                                        this.state.estados.map(data => {
                                            return (
                                                <MenuItem value={data.id}>{data.estado}</MenuItem>
                                            )
                                        })
                                    }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={5} md={5}>
                                <InputLabel style={{marginLeft:'20px'}}>Email</InputLabel>
                                <TextField id="txtEmail" name="email" margin="normal" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}}/>
                            </Grid>
                        </Grid>

                </div>
            </div>
        )
    }
}
export default Cliente;