import React from 'react';
import Login from './pages/login'
import Cotizador from './pages/cotizador';
import Cotizaciones from './pages/cotizaciones'
import Clientes from './pages/clientes';
import Cliente from './pages/cliente';
import Cotizacion from './pages/cotizacion'
import Dashboard from './pages/dashboard';
import Usuarios from './pages/usuarios';
import { Authentication } from './services/authentication';
import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import './App.css';
import './index.css';

import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function App() {
    return (
        <BrowserRouter>
        <Switch>
            <PublicRoute restricted={true} path="/" component={Login}  exact></PublicRoute>
            <PublicRoute restricted={false} path="/clientes" component={Clientes} exact></PublicRoute>
            <PrivateRoute component={Cotizaciones} path="/cotizaciones" exact></PrivateRoute>
            <PrivateRoute component={Cotizador} path="/cotizador" exact></PrivateRoute>
            <PrivateRoute component={Cliente} path="/cliente" exact></PrivateRoute>
            <PrivateRoute component={Cotizacion} path="/cotizacion" exact></PrivateRoute>
            <PrivateRoute component={Dashboard} path="/dashboard" exact></PrivateRoute>
            <PrivateRoute component={Usuarios} path="/usuarios" exact></PrivateRoute>
        </Switch>
        </BrowserRouter>
    );
}