import React, { Component, Fragment,PureComponent } from 'react';
import Routes from '../Routes';
import { environment } from '../config/config';
import { Chart, ArgumentAxis, ValueAxis, LineSeries,SplineSeries } from "@devexpress/dx-react-chart-material-ui";
import { Authentication } from '../services/authentication';


import {
    PieChart, Pie, Sector,
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,LineChart, Line
  } from 'recharts';


import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardMedia from '@material-ui/core/CardMedia'
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import $ from 'jquery';
import { borders } from '@material-ui/system';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

class Dashboard extends Component{
    url = environment.url;

    usr = JSON.parse(localStorage.currentUser);

    constructor(props){

        super(props);

        if (!Authentication.isLogin()) {
            this.props.history.push('/');
        }

        this.state = {
            totmetas:{},
            totcots:{},
            totcotsanio:{},
            totservsmonth:{},
            totservsmonthsemester:{},
            porcentajes:{},
            porcentajesMonthSem:{},
            porcentajesAnio:{},
            pieGraphData:[],
            pieGraphColor:[],
            totcostsem:{},
            barGrapData:[],
            lineGrapData:[],
            dashboards:'cots',
            currentmonth:'',
            months:['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
            asesor:[],
            selasesor:{id:0,value:'Generales'}
            // totscots:0,
            // totsEnProceso:0,
        }
        this.moneyFormat = this.moneyFormat.bind(this);
        this.changeAsesor = this.changeAsesor.bind(this);

        this.onClickBtnDashboards = this.onClickBtnDashboards.bind(this);
        document.getElementById('root').style.marginLeft = "0px";
        //descomentar getTotalesMetas

        //comentar getTotales
        this.getTotalesMes();
        this.getTotalesSem();
        // this.getTotalesAnio();
        this.getTotalesServsMes();
        this.getTotServsMesSem();
        
        let usr = JSON.parse(localStorage.getItem('currentUser'));
        if(usr.user.idtipo === 1){
            this.getAsesor();
        }
    }

    async getTotalesMes(){
        // let usr = JSON.parse(localStorage.currentUser);
        await axios.get(`${this.url}/api/dashboard/cots/totales/mes?usr=${this.usr.user.idusuario}&tipo=${this.usr.user.idtipo}&asr=${this.state.selasesor.id}`)
        .then(response => {
            this.setTotalesMes(response.data[0]);
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async getTotalesSem(){
        // let usr = JSON.parse(localStorage.currentUser);
        await axios.get(`${this.url}/api/dashboard/cots/totales/sem?usr=${this.usr.user.idusuario}&tipo=${this.usr.user.idtipo}&asr=${this.state.selasesor.id}`)
        .then(response => {
            console.log(response.data[0]);
            this.setTotalesSem(response.data[0]);
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async getTotalesAnio(){
        await axios.get(`${this.url}/api/dashboard/cots/totales/anio`)
        .then(response => {
            // console.log(response.data[0]);
            this.setTotalesAnio(response.data[0]);
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async getTotalesServsMes(){
        await axios.get(`${this.url}/api/dashboard/cots/serv/mes?&asr=${this.state.selasesor.id}`)
        .then(response => {
            console.log(response.data);
            this.setTotalesServsMes(response.data);
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async getTotServsMesSem(){
        await axios.get(`${this.url}/api/dashboard/cots/serv/messem?&asr=${this.state.selasesor.id}`)
        .then(response => {
            this.setTotServsMesSem(response.data[0]);
            // console.log(response.data);
            // this.setTotalesServsMes(response.data);
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    setTotalesMes(totales){
        this.state.totcots = totales;

        this.state.pieGraphData = [
            { name: 'rechazadas', value: this.state.totcots.rechazadasmes }, //rosa
            { name: 'en proceso', value: this.state.totcots.enprocesomes }, // amarillo
            { name: 'enviadas', value: this.state.totcots.enviadasmes }, //morado
            { name: 'en negociacion', value: this.state.totcots.negociacionmes }, //azul
            { name: 'aceptadas', value: this.state.totcots.aceptadasmes }, //verde
          ];
                                    //rosa   amarillo   morado      azul     verde
        this.state.pieGraphColor= ['#F400CF','#F4E100','#6E53A1','#11A1F2','#00C726'];

        let objPorcentajes = {
            procesoMes: this.setPorcentaje(this.state.totcots.enprocesomes,140),
            enviadasMes: this.setPorcentaje(this.state.totcots.enviadasmes,140),
            negociacionMes: this.setPorcentaje(this.state.totcots.negociacionmes,140),
            aceptadasMes: this.setPorcentaje(this.state.totcots.aceptadasmes,140),
            rechazadasMes: this.setPorcentaje(this.state.totcots.rechazadasmes,140),
            procesoSem: this.setPorcentaje(this.state.totcots.enprocesosem,140),
            enviadasSem: this.setPorcentaje(this.state.totcots.enviadasem,140),
            negociacionSem: this.setPorcentaje(this.state.totcots.negociacionsem,140),
            aceptadasSem: this.setPorcentaje(this.state.totcots.aceptadassem,140),
            rechazadasSem: this.setPorcentaje(this.state.totcots.rechazadassem,140),
        }

        this.state.porcentajes = objPorcentajes;

        this.setState({
            totcots: this.state.totcots,
            porcentajes: this.state.porcentajes
        });

    }

    setTotalesSem(totales){
        this.state.totcostsem = totales;

        let objData = [
            {
              dia: this.state.totcostsem.dayone.toString(), cots: this.state.totcostsem.dayonecots,
            },
            {
              dia: this.state.totcostsem.daytwo.toString(), cots: this.state.totcostsem.daytwocots,
            },
            {
              dia: this.state.totcostsem.daythree.toString(), cots: this.state.totcostsem.daythreecots,
            },
            {
              dia: this.state.totcostsem.dayfour.toString(), cots: this.state.totcostsem.dayfourcots,
            },
            {
              dia: this.state.totcostsem.dayfive.toString(), cots: this.state.totcostsem.dayfivecots,
            },
            {
              dia: this.state.totcostsem.daysix.toString(), cots: this.state.totcostsem.daysixcots,
            },
            {
              dia: this.state.totcostsem.dayseven.toString(), cots: this.state.totcostsem.daysevencots,
            },
          ];

        this.state.barGrapData = objData;

        this.setState({
            totcostsem: this.state.totcostsem,
            barGrapData: this.state.barGrapData
        });
    }

    setTotalesAnio(totales){
        this.state.totcotsanio = totales;

        let objPorcentajes = {
            prMonth: this.setPorcentaje(this.state.totcotsanio.cotsmonth,this.state.totmetas.month),
            prAnio: this.setPorcentaje(this.state.totcotsanio.cotsyear,this.state.totmetas.year)
        }

        this.state.porcentajesAnio = objPorcentajes;

        this.setState({
            totcotsanio: this.state.totcotsanio,
            porcentajesAnio: this.state.porcentajesAnio
        });
    }

    setTotalesServsMes(totales){
        this.state.lineGrapData = totales;

        this.setState({
            lineGrapData:this.state.lineGrapData
        })
    }

    setTotServsMesSem(totales){
        this.state.totservsmonthsemester = totales;
        this.state.currentmonth = this.state.months[totales.currentmonth-1];

        let objPorcentajes = {
            prPltMonth: this.setPorcentaje(this.state.totservsmonthsemester.plataformames,this.state.totservsmonthsemester.metames),
            prPltYear: this.setPorcentaje(this.state.totservsmonthsemester.plataformaanual,this.state.totservsmonthsemester.metaanual),
            prVerMonth: this.setPorcentaje(this.state.totservsmonthsemester.verificacionesmes,this.state.totservsmonthsemester.metames),
            prVerYear: this.setPorcentaje(this.state.totservsmonthsemester.verificacionesanual,this.state.totservsmonthsemester.metaanual),
            prSocMonth: this.setPorcentaje(this.state.totservsmonthsemester.socioeconomicosmes,this.state.totservsmonthsemester.metames),
            prSocYear: this.setPorcentaje(this.state.totservsmonthsemester.socioeconomicosanual,this.state.totservsmonthsemester.metaanual),
            prPscMonth: this.setPorcentaje(this.state.totservsmonthsemester.psicometriasmes,this.state.totservsmonthsemester.metames),
            prPscYear: this.setPorcentaje(this.state.totservsmonthsemester.psicometriasanual,this.state.totservsmonthsemester.metaanual),
            prMonth: this.setPorcentaje(this.state.totservsmonthsemester.totalmes,this.state.totservsmonthsemester.metames),
            prYear: this.setPorcentaje(this.state.totservsmonthsemester.totalanual,this.state.totservsmonthsemester.metaanual)
            // ,
            // prRecMonth: this.setPorcentaje(this.state.totservsmonthsemester.reclutamientosmes,this.state.totservsmonthsemester.metames),
            // prRecYear: this.setPorcentaje(this.state.totservsmonthsemester.reclutamientossemestre,this.state.totservsmonthsemester.metaanual)
        }

        this.state.porcentajesMonthSem = objPorcentajes;
        
        this.setState({
            totservsmonthsemester:this.state.totservsmonthsemester,
            porcentajesMonthSem: this.state.porcentajesMonthSem,
            currentmonth: this.state.currentmonth
        });

    }

    setPorcentaje(cantidad,total){
        let tot = (cantidad * 100 ) / (total)

        if(tot > 100)
            return "100%"
        else
            return tot.toString().concat("%")
    }

    onClickBtnDashboards(e){
        this.state.dashboards = e.currentTarget.id;
        this.setState({
            dashboards : this.state.dashboards
        });
    }

    moneyFormat(Qantity){
        let qnt = Qantity;
        
        if(qnt === undefined || qnt === null || qnt <= 0)
            qnt = 0 ;

        return '$'+(qnt).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

    async getAsesor(){
        await axios.get(`${this.url}/api/cotizador/asesor`)
        .then(response => {
            this.setState({asesor:response.data})
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    changeAsesor(e){
        console.log(e.target.value)
        this.state.selasesor.id=e.target.value
        this.setState({selasesor:this.state.selasesor});
        this.getTotalesMes();
        this.getTotalesSem();
        this.getTotalesServsMes();
        this.getTotServsMesSem();
        // this.setState({idAsesor:e.target.value.id,idUsuario:e.target.value.idusuario})
    }

    render(){

        return (
            <div>
                <Routes/>
                <div id={"content"}>

                    {/* texto inicio */}
                    <Grid item xs={12} sm={12} md={12} style={{marginLeft:"80px",marginTop:"40px"}} container>
                        <Grid item xs={12} sm={12} md={12} style={{textAlign:"left",marginBottom:"-40px"}}>
                            <p style={{fontSize:"20px",color:"#676666"}}>Buen día, {JSON.parse(localStorage.currentUser)['user'].name}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} style={{textAlign:"left",display:"flex"}}>
                            <img src="dashboard-blue.svg" width="20px" height="20px" style={{marginRight:"5px",marginTop:"20px"}}></img>
                            <p style={{fontSize:"20px",color:"#0f3b6e"}}>Checa aquí las estadisticas</p>
                        </Grid>
                    </Grid>

                    { JSON.parse(localStorage.currentUser)["user"]["idtipo"] == 1 ?
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} >
                            <FormControl variant="outlined" style = {{width: "91%",marginLeft:'80px',top:'31px'}}>
                                <InputLabel id="lblAsesor" style={{marginLeft:'20px',top:"10px"}}>Asesor</InputLabel>
                                    <Select
                                        id="selAsesor"
                                        value={this.state.selasesor.id}
                                    // renderValue={(selected) => {console.log(selected);return(<div>{this.state.estado != "" ? this.state.estado : this.state.cliente.estado}</div>)}}
                                        onChange={this.changeAsesor}
                                    >
                                    <MenuItem value={0}>Generales</MenuItem>
                                    {
                                        this.state.asesor.map(data => {
                                            return (
                                                <MenuItem value={data.id}>{data.descripcion}</MenuItem>
                                                )
                                        })
                                    }   
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    :''
                    }


                        {/* {pie graph and bar graph} */}
                        <Grid  item xs={12} sm={12} md={12} style={{marginLeft:"80px",marginTop:"40px"}} container>
                                {/* pie graph */}
                                <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",}}>
                                    <Grid item xs={12} sm={12} md={12} style={{textAlign:"center",backgroundColor:"#F5F5F5",height:"12%",paddingTop:"1px",paddingBottom:"1px",marginBottom:"-50px"}}>
                                        <p style={{color:"#0f3b6e",fontSize:"18px",marginBottom:"45px"}}>COTIZACIONES MENSUAL</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} style={{textAlign:"center",marginLeft:"25%"}}>
                                        <PieChart width={800} height={400} >
                                            <g>
                                            <text x={120} y={210} dy={8} textAnchor="middle" style={{fontSize:"30px",fontWeight:"700",fill:"#626262"}}>{this.state.totcots.totalcots}</text>
                                            </g>
                                            <Pie
                                                data={this.state.pieGraphData}
                                                cx={120}
                                                cy={200}
                                                innerRadius={80}
                                                outerRadius={100}
                                                fill="#8884d8"
                                                paddingAngle={5}
                                                dataKey="value"
                                            >
                                            {
                                                this.state.pieGraphData.map((entry, index) => <Cell key={`cell-${index}`} fill={this.state.pieGraphColor[index % this.state.pieGraphColor.length]} />)
                                            }
                                            </Pie>
                                        </PieChart>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} style={{marginLeft:"25px"}} container>
                                        <div style={{display:"flex",width:"19%",marginLeft:"-10px"}}>
                                            <span style={{height:"10px",width:"10px",borderRadius:"50%",display:"inline-block",backgroundColor:"#F4E100",marginTop:"10px"}}></span>
                                            <p style={{color:"#626262",fontSize:"10px",marginLeft:"3px"}}>PROCESO</p>
                                        </div>
                                        <div style={{display:"flex",width:"19%"}}>
                                            <span style={{height:"10px",width:"10px",borderRadius:"50%",display:"inline-block",backgroundColor:"#6E53A1",marginTop:"10px"}}></span>
                                            <p style={{color:"#626262",fontSize:"10px",marginLeft:"3px"}}>ENVIADAS</p>
                                        </div>
                                        <div style={{display:"flex",width:"20%"}}>
                                            <span style={{height:"10px",width:"10px",borderRadius:"50%",display:"inline-block",backgroundColor:"#11A1F2",marginTop:"10px"}}></span>
                                            <p style={{color:"#626262",fontSize:"10px",marginLeft:"3px"}}>NEGOCIACIÓN</p>
                                        </div>
                                        <div style={{display:"flex",width:"19%"}}>
                                            <span style={{height:"10px",width:"10px",borderRadius:"50%",display:"inline-block",backgroundColor:"#00C726",marginTop:"10px"}}></span>
                                            <p style={{color:"#626262",fontSize:"10px",marginLeft:"3px"}}>ACEPTADAS</p>
                                        </div>
                                        <div style={{display:"flex",width:"19%"}}>
                                            <span style={{height:"10px",width:"10px",borderRadius:"50%",display:"inline-block",backgroundColor:"#F400CF",marginTop:"10px"}}></span>
                                            <p style={{color:"#626262",fontSize:"10px",marginLeft:"3px"}}>RECHAZADAS</p>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={1} md={1}>
                                </Grid>
                                {/* bar graph */}
                                <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8"}}>
                                    <Grid item xs={12} sm={12} md={12} style={{textAlign:"center",backgroundColor:"#F5F5F5",height:"12%",paddingTop:"1px",paddingBottom:"1px",marginBottom:"-50px"}}>
                                        <p style={{color:"#0f3b6e",fontSize:"18px",marginBottom:"45px"}}>NUEVAS COTIZACIONES / SEMANA</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} style={{textAlign:"center",marginTop:"18%",display:"flex",marginRight:"50px"}}>
                                        <BarChart style={{width:"100%"}}
                                            width={400}
                                            height={400}
                                            data={this.state.barGrapData}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="dia" />
                                            <YAxis />
                                            <Tooltip />
                                            {/* <Legend /> */}
                                            <Bar dataKey="cots" stackId="a" fill="#1864BC" />
                                        </BarChart>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={1} md={1}>
                                </Grid>
                        </Grid>

                        {/* cards totales mes año */}
                        <Grid item xs={12} sm={12} md={12} style={{marginLeft:"80px",marginTop:"40px"}} container>
                            {/* card mes*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #da0000"}} container>
                                <Grid item xs={12} sm={12} md={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <p style={{fontSize:"20px",color:"#da0000",marginLeft:"30px"}}>META MENSUAL</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",marginTop:"18px",textAlign:"right",marginRight:"10px"}}>{this.moneyFormat(this.state.totservsmonthsemester.metames)}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{}} container>
                                    <Grid item xs={12} sm={12} md={12} style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #da0000",height:"20px",bottom:"22px",backgroundColor:"#da0000",position:"relative",width:this.state.porcentajesMonthSem.prMonth }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",marginTop:"-20px",textAlign:"left"}}>{this.moneyFormat(this.state.totservsmonthsemester.totalmes)}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} >
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                            {/* card año*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #da0000"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={6} ms={6}>
                                        <p style={{fontSize:"20px",color:"#da0000",marginLeft:"30px"}}>META ANUAL</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} ms={6}>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",marginTop:"18px",textAlign:"right",marginRight:"10px"}}>{this.moneyFormat(this.state.totservsmonthsemester.metaanual)}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                        <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} ms={12} style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #da0000",height:"20px",bottom:"22px",backgroundColor:"#da0000",position:"relative",width:this.state.porcentajesMonthSem.prYear }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",marginTop:"-20px",textAlign:"left"}}>{this.moneyFormat(this.state.totservsmonthsemester.totalanual)}</p>
                                    </Grid>
                                    <Grid item xs={1} sm={1} ms={1} >
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                        </Grid>

                        {/* cards plataforma */}
                        <Grid item xs={12} sm={12} md={12} style={{marginLeft:"80px",marginTop:"40px"}} container>
                            {/* card mes plataforma*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #F4E100"}} container>
                                <Grid item xs={12} sm={12} md={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} md={10}>
                                        <p style={{fontSize:"20px",color:"#F4E100",marginLeft:"30px"}}>META MENSUAL PLATAFROMA 24/7</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} md={12}>
                                    <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} md={12} style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #F4E100",height:"20px",bottom:"22px",backgroundColor:"#F4E100",position:"relative",width:this.state.porcentajesMonthSem.prPltMonth }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",marginTop:"-20px",textAlign:"left"}}>{this.moneyFormat(this.state.totservsmonthsemester.plataformames)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                            {/* card semestre plataforma*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #F4E100"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#F4E100",marginLeft:"30px"}}>META ANUAL PLATAFROMA 24/7</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                        <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}  style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #F4E100",height:"20px",bottom:"22px",backgroundColor:"#F4E100",position:"relative",width:this.state.porcentajesMonthSem.prPltYear }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",textAlign:"left",marginTop:"-20px"}}>{this.moneyFormat(this.state.totservsmonthsemester.plataformaanual)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                        </Grid>

                        {/* cards verificaciones */}
                        <Grid item xs={12} sm={12} md={12} style={{marginLeft:"80px",marginTop:"40px"}} container>
                            {/* card mes verificaciones*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #6E53A1"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#6E53A1",marginLeft:"30px"}}>META MENSUAL VERIFICACIONES</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                    <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}  style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #6E53A1",height:"20px",bottom:"22px",backgroundColor:"#6E53A1",position:"relative",width:this.state.porcentajesMonthSem.prVerMonth }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",textAlign:"left",marginTop:"-20px"}}>{this.moneyFormat(this.state.totservsmonthsemester.verificacionesmes)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                            {/* card semestre verificaciones*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #6E53A1"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#6E53A1",marginLeft:"30px"}}>META ANUAL VERIFICACIONES</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",marginLeft:"30px",fontWeight:"bold",marginTop:"15px"}}></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                        <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}  style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #6E53A1",height:"20px",bottom:"22px",backgroundColor:"#6E53A1",position:"relative",width:this.state.porcentajesMonthSem.prVerYear }}></div>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"-20px",textAlign:"left"}}>{this.moneyFormat(this.state.totservsmonthsemester.verificacionesanual)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                        </Grid>

                        {/* cards socioeconomicos */}
                        <Grid item xs={12} sm={12} md={12} style={{marginLeft:"80px",marginTop:"40px"}} container>
                            {/* card mes socioeconomicos*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #11A1F2"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#11A1F2",marginLeft:"30px"}}>META MENSUAL SOCIOECONOMICOS</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                    <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}  style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #11A1F2",height:"20px",bottom:"22px",backgroundColor:"#11A1F2",position:"relative",width:this.state.porcentajesMonthSem.prSocMonth }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",textAlign:"left",marginTop:"-20px"}}>{this.moneyFormat(this.state.totservsmonthsemester.socioeconomicosmes)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                            {/* card semestre socioeconomicos*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #11A1F2"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#11A1F2",marginLeft:"30px"}}>META ANUAL SOCIOECONOMICOS</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                        <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} ms={12} style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #11A1F2",height:"20px",bottom:"22px",backgroundColor:"#11A1F2",position:"relative",width:this.state.porcentajesMonthSem.prSocYear }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",textAlign:"left",marginTop:"-20px"}}>{this.moneyFormat(this.state.totservsmonthsemester.socioeconomicosanual)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                        </Grid>

                        {/* cards psicometricos */}
                        <Grid item xs={12} sm={12} md={12} style={{marginLeft:"80px",marginTop:"40px"}} container>
                            {/* card mes psicometricos*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #00C726"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#00C726",marginLeft:"30px"}}>META MENSUAL PSICOMETRICOS</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                    <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} ms={12} style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #00C726",height:"20px",bottom:"22px",backgroundColor:"#00C726",position:"relative",width:this.state.porcentajesMonthSem.prPscMonth }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",marginTop:"-20px",textAlign:"left"}}>{this.moneyFormat(this.state.totservsmonthsemester.psicometriasmes)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                            {/* card semestre psicometricos*/}
                            <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #00C726"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#00C726",marginLeft:"30px"}}>META ANUAL PSICOMETRICOS</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}></p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                        <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>{this.state.currentmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={12} ms={12} style={{marginLeft:"25px",marginRight:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #00C726",height:"20px",bottom:"22px",backgroundColor:"#00C726",position:"relative",width:this.state.porcentajesMonthSem.prPscYear }}></div>
                                        <p style={{fontSize:"22px",color:"#626262",fontWeight:"bold",textAlign:"left",marginTop:"-20px"}}>{this.moneyFormat(this.state.totservsmonthsemester.psicometriasanual)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* espacio en blanco */}
                            <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                        </Grid>

                        {/* cards reclutamiento */}
                        {/* <Grid item xs={12} sm={12} md={12} style={{marginLeft:"80px",marginTop:"40px"}} container> */}
                            {/* card mes reclutamiento*/}
                            {/* <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #F400CF"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#F400CF",marginLeft:"30px"}}>META MENSUAL RECLUTAMIENTO</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}>{this.state.totmetas.recmonth}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                    <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>Marzo</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={2} ms={2} >
                                        <p style={{fontSize:"25px",color:"#626262",marginLeft:"30px",fontWeight:"bold"}}>#{this.state.totservsmonthsemester.reclutamientosmes}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={9} ms={9}  style={{marginLeft:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={{borderRadius:"25px",border:"1px solid #F400CF",height:"20px",bottom:"22px",backgroundColor:"#F400CF",position:"relative",width:this.state.porcentajesMonthSem.prRecMonth }}></div>
                                    </Grid>
                                    <Grid item xs={1} sm={1} ms={1} >
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            {/* espacio en blanco */}
                            {/* <Grid item xs={12} sm={1} md={1}>
                            </Grid> */}
                            {/* card semestre reclutamiento*/}
                            {/* <Grid item xs={12} sm={5} md={5} style={{borderRadius:"8px",border:"1px solid #D8D8D8",height:"200px",borderLeft:"10px solid #F400CF"}} container>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-50px"}} container>
                                    <Grid item xs={12} sm={10} ms={10}>
                                        <p style={{fontSize:"20px",color:"#F400CF",marginLeft:"30px"}}>META SEMESTRAL RECLUTAMIENTO</p>
                                    </Grid>
                                    <Grid item xs={12} sm={2} ms={2}>
                                        <p style={{fontSize:"25px",color:"#626262",fontWeight:"bold",marginTop:"15px"}}>{this.state.totmetas.recsem}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{marginBottom:"-15px"}} container>
                                    <Grid item xs={12} sm={12} ms={12}>
                                        <p style={{fontSize:"20px",color:"#A7A7A7",marginLeft:"30px"}}>Marzo</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} ms={12} style={{paddingBottom:"25px"}} container>
                                    <Grid item xs={12} sm={2} ms={2} >
                                        <p style={{fontSize:"25px",color:"#626262",marginLeft:"30px",fontWeight:"bold"}}>#{this.state.totservsmonthsemester.reclutamientossemestre}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={9} ms={9}  style={{marginLeft:"10px"}}>
                                        <div style={{borderRadius:"25px",border:"1px solid #D8D8D8",height:"20px",marginTop:"30px",backgroundColor:"#D8D8D8",zIndex:"-1",position:"relative"}}></div>
                                        <div style={
                                            {borderRadius:"25px",border:"1px solid #F400CF",height:"20px",bottom:"22px",backgroundColor:"#F400CF",position:"relative",width:this.state.porcentajesMonthSem.prRecSem }}></div>
                                    </Grid>
                                    <Grid item xs={1} sm={1} ms={1} >
                                    </Grid>
                                </Grid>
                            </Grid> */}
                            {/* espacio en blanco */}
                            {/* <Grid item xs={12} sm={1} md={1}>
                            </Grid>
                        </Grid> */}

 
                        {/* prospeccion mensual lineal graph */}
                        <Grid style={{marginLeft:"80px",marginTop:"40px",width:"92%"}} container>
                            <Grid item xs={12} sm={12} md={12} style={{borderRadius:"8px",border:"1px solid #D8D8D8"}}>
                                <Grid item xs={12} sm={12} md={12} style={{textAlign:"center",backgroundColor:"#F5F5F5",height:"12%",paddingTop:"1px",paddingBottom:"1px",marginBottom:"-50px"}}>
                                    <p style={{color:"#0f3b6e",fontSize:"18px",marginBottom:"45px"}}>PROSPECCIÓN MENSUAL</p>
                                    <Grid item xs={12} sm={12} md={12} style={{display:"flex"}}>
                                        <LineChart style={{width:"100%",marginBottom:"50px",marginLeft:"-25px"}}
                                            width={950}
                                            height={400}
                                            data={this.state.lineGrapData}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="day" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="plataformas" stroke="#F4E100" fill="#F4E100"  />
                                            <Line type="monotone" dataKey="verificaciones" stroke="#6E53A1" fill="#6E53A1"  />
                                            <Line type="monotone" dataKey="socioeconomicos" stroke="#11A1F2" fill="#11A1F2"  />
                                            <Line type="monotone" dataKey="psicometricos" stroke="#00C726" fill="#00C726"  />
                                            <Line type="monotone" dataKey="reclutamientos" stroke="#F400CF" fill="#F400CF"  />
                                        </LineChart>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                </div>
            </div>
        )
    }
}
export default Dashboard;
