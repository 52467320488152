import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { environment } from '../config/config'

// const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const url = environment.url;

const TOKEN_KEY = 'jwt';

export const Authentication = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    isLogin,
    userId : localStorage.getItem('jwt')==="undefined" || localStorage.getItem('jwt')===null ? null:JSON.parse(localStorage.currentUser).user.idUsuario,
    get currentUserValue () { return currentUserSubject.value }
};


async function login(usr){
    await axios.post(`${url}/api/auth/login`,{usr})
    .then(response => { 
        if(response.data.success){
        // localStorage.setItem('currentUser', response.data);
        localStorage.setItem(TOKEN_KEY,response.data.token);
        localStorage.setItem('currentUser', JSON.stringify(response.data));
        currentUserSubject.next(response.data);
        return response;
        }
    })
    .catch(error => {
        console.log(error.response)
    });
}

function logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
    window.location.replace("/");
}


function isLogin(){
    // if(localStorage.getItem('currentUser')){
        if(localStorage.getItem(TOKEN_KEY)){
        return true;
    }

// function userId(){
//     return ;
// }   
}
// }
