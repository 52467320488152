import React, { Component, Fragment } from 'react';
import { environment } from '../config/config'
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import MenuItem from '@material-ui/core/MenuItem';
// import Option from '@material-ui/core/Option';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Routes from '../Routes'
import $ from 'jquery';
import { Authentication } from '../services/authentication';
 import AddCircleIcon from '@material-ui/icons/AddCircle';
 import NavigateNextIcon from '@material-ui/icons/NavigateNext';
 import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

class Cotizaciones extends Component{
    url = environment.url;

    constructor(props){
        super(props);

        if (!Authentication.isLogin()) {
            this.props.history.push('/');
        }

        this.state = {
            cotizaciones:[],
            idcli:undefined,
            clientes:[],
            cliente:{},
            estados:[],
            status:[],
            asesor:[],
            idEstado:"",
            estado:"",
            representante:props.representante,
            empresa:props.empresa,
            puesto:props.puesto,
            telefono:props.telefono,
            extension:props.extension,
            celular:props.celular,
            domicilio:props.domicilio,
            ciudad:props.ciudad,
            email:props.email,
            celular:props.celular,
            idAsesor:0,
            descripcion:'',
            idCot:0,
            selidstatus:0,
            selestatus:'',
            currentStatus:1,
            currentEstatus: 'En Proceso',
            search:'',
            currentPage:1,
            rowsPerPage:10,
            totalRows:0,
            totalPages:1,
            initRow:1,
            endRow:10,
            prevPage:0,
            nextPage:0,
            cotizacionesPage:[],
            pagination:false
        }

        this.changeCliente = this.changeCliente.bind(this);
        this.changeEstado = this.changeEstado.bind(this);
        this.updateClientData = this.updateClientData.bind(this);
        this.addCot = this.addCot.bind(this);
        this.changeAsesor = this.changeAsesor.bind(this);
        this.getAsesorValue = this.getAsesorValue.bind(this);
        this.getUsuarioValue = this.getUsuarioValue.bind(this);
        this.clickCotizacion = this.clickCotizacion.bind(this);
        this.onChangeStatusCotizacion = this.onChangeStatusCotizacion.bind(this);
        this.changeStatusCotizacion = this.changeStatusCotizacion.bind(this);
        this.onClickStatus = this.onClickStatus.bind(this);
        this.onClickChangePage = this.onClickChangePage.bind(this);
        
        this.setState({idEstado:localStorage.est});
        
        this.getStatus();
        this.getClient();
        this.getListClients();

        let usr = JSON.parse(localStorage.getItem('currentUser'));
        if(usr.user.idtipo === 1){
            this.getAsesor();
        }
    }

    async getStatus(){
        document.getElementById('root').style.marginLeft = "0px";
     
        await axios.get(`${this.url}/api/cotizaciones/estatus`)
        .then(response => {
            this.setState({status:response.data})
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    getClient(){
        if(localStorage.cli != undefined){
            this.getCotizaciones(localStorage.cli,this.state.currentStatus);
        }else{
            this.setState({idcli:undefined})
        }
    }

    changeCliente(e){
        this.state.currentEstatus = 'En Proceso';
        this.setState({currentEstatus:this.state.currentEstatus})
        this.getCotizaciones(e.target.value,1);
    }

    changeEstado(e){
        this.setState({estado:e.currentTarget.innerText});
        this.setState({idEstado:e.target.value});
    }

    changeRepresentante(e){
        console.log(e)
    }

    handleChange(e){
        this.setState({
            [e.target.name]: e.target.value
        });
        
        if(e.target.name === "search")
            this.search(e.target.value);
    }

    async search(search){
        await axios.get(`${this.url}/api/cotizaciones/search?search=${search}&idCli=${this.state.idcli}`)
        .then(response => {
            console.log(response);
            this.state.currentStatus = 0;
            this.state.currentEstatus = 'TODOS'
            this.setState({
                cotizaciones:response.data,
                currentEstatus: this.state.currentEstatus,
                currentStatus: this.state.currentStatus,
                totalRows:response.data[0].total_rows
            });
            this.setPagination();
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    updateClientData(e){
        let cliente = {
            idcli:this.state.idcli,
            representante:document.getElementById("txtRepresentante").value,
            puesto:document.getElementById("txtPuesto").value,
            telefono:document.getElementById("txtTelefono").value,
            extension:document.getElementById("txtExt").value,
            celular:document.getElementById("txtCelular").value,
            domicilio:document.getElementById("txtDomicilio").value,
            ciudad:document.getElementById("txtCiudad").value,
            idEstado:this.state.idEstado,
            email:document.getElementById("txtEmail").value,
            idAsesor:this.getAsesorValue(),
            idUsuario:this.getUsuarioValue()
        }
        console.log(cliente);
        this.setClientData(cliente);
    }

    async setClientData(client){
        await axios.post(`${this.url}/api/cotizaciones/client/update`,{client})
        .then(result => {
            console.log(result);
            // this.setState({estados:result.data});
        })
    }

    async getEstados(){
        await axios.get(`${this.url}/api/cotizador/estados`)
        .then(result => {
            this.setState({estados:result.data});
        })
    }

    async getClientData(){
        await axios.get(`${this.url}/api/cotizaciones/client/data?cli=${this.state.idcli}`)
        .then(response => {
            console.log(response.data);
            this.setState({cliente:response.data[0]});
            this.setState({empresa:response.data[0].empresa});
            this.setState({representante:response.data[0].representante});
            this.setState({puesto:response.data[0].puesto});
            this.setState({telefono:response.data[0].telefono});
            this.setState({extension:response.data[0].extension});
            this.setState({celular:response.data[0].celular});
            this.setState({domicilio:response.data[0].domicilio});
            this.setState({ciudad:response.data[0].ciudad});
            this.setState({email:response.data[0].email});
            this.setState({idEstado:response.data[0].idestado});
            this.setState({estado:response.data[0].estado});
            this.setState({idAsesor:response.data[0].idasesor});
            this.setState({descripcion:response.data[0].descripcion});
            localStorage.est = this.state.idEstado;
            this.getEstados();
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    async getCotizaciones(idcli,st){
        await axios.get(`${this.url}/api/cotizaciones/client/get?cli=${idcli}&st=${st}`)
        .then(response => {
            if(response.data.length > 0){
            this.setState({
                cotizaciones:response.data,
                idcli:idcli,
                totalRows:response.data[0].total_rows
            });
        }else{
            this.setState({
                cotizaciones:[],
                idcli:idcli,
                totalRows:0
            });
        }
            localStorage.cli = this.state.idcli;
            this.setPagination();
            this.getClientData();
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    setPagination(){
        if(this.state.totalRows > 10){
            this.state.pagination = true;
            this.state.totalPages = this.state.totalRows / this.state.rowsPerPage;
            
            if(this.state.totalPages % 1 === 0 )
                this.state.totalPages = parseInt(this.state.totalPages)
            else
                this.state.totalPages = parseInt(this.state.totalPages)+1;

            if(this.state.currentPage > 1){
                
                if(this.state.totalPages === this.state.currentPage){
                    this.state.prevPage = this.state.totalPages - 1;
                    this.state.nextPage = 0;
                    this.state.initRow = (this.state.currentPage * this.state.rowsPerPage) - this.state.rowsPerPage + 1;
                    this.state.endRow = this.state.totalRows;
                }else{
                    this.state.prevPage = this.state.currentPage -1;
                    this.state.nextPage = this.state.currentPage + 1;
                    this.state.initRow = (this.state.currentPage * this.state.rowsPerPage) - this.state.rowsPerPage + 1;
                    this.state.endRow = this.state.currentPage * this.state.rowsPerPage;
                }
            }else{
                this.state.prevPage = 0;
                this.state.nextPage = 2;
                this.state.initRow = 1;
                this.state.endRow = 10;
            }

        }else{
            this.state.pagination = false;
            this.state.currentPage = 1;
            this.state.totalPages = 1;
            this.state.initRow = 1;
            this.state.endRow = this.state.totalRows;
            this.state.prevPage = 0;
            this.state.prevPage = 0;
        }

        let cotsTmp = [];
        for(let i=this.state.initRow-1;i<this.state.endRow;i++){
            cotsTmp.push(this.state.cotizaciones[i]);
        }
        this.state.cotizacionesPage = cotsTmp;
        this.setState({cotizacionesPage:this.state.cotizacionesPage})
    }

    async getListClients(){
        let usr = JSON.parse(localStorage.getItem('currentUser'));
        await axios.get(`${this.url}/api/cotizaciones/clients/get?usr=${usr.user.idUsuario}&tipo=${usr.user.idTipo}`)
        .then(response => {
            this.setState({clientes:response.data})
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    addCot(e){
        this.props.history.push('/cotizador');
    }

    async getAsesor(){
        await axios.get(`${this.url}/api/cotizador/asesor`)
        .then(response => {
            this.setState({asesor:response.data})
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    changeAsesor(e){
        console.log(e.target.value)
        this.setState({idAsesor:e.target.value.id,idUsuario:e.target.value.idusuario})
    }

    getAsesorValue(){
        if(this.state.idAsesor > 0)
            return this.state.idAsesor;
        else
            return 0;
    }

    getUsuarioValue(){
        if(this.state.idUsuario > 0)
            return this.state.idUsuario;
        else
            return 0;
    }

    clickCotizacion(e){
        this.state.cotizaciones.map(data => {
            if(parseInt(e.currentTarget.value) === data.idcotizacion){
                localStorage.setItem('cot', e.currentTarget.value);
                localStorage.setItem('fol', data.folio);
                localStorage.setItem('rev', data.revision);
                // break;
            }
        })

        // localStorage.setItem('cot', e.currentTarget.value);
        this.props.history.push('/cotizacion');
        // let id = e.target.id;
        // this.props.history.push({pathname:'/Cotizacion',data:id});
    }

    onChangeStatusCotizacion(e){
        let cotizacion = {
            idCotizacion: e.target.name.idcotizacion,
            idEstatus:e.target.value
        }
        this.changeStatusCotizacion(cotizacion);
    }

    async changeStatusCotizacion(cotizacion){
        await axios.post(`${this.url}/api/cotizaciones/updatestatus`,{cotizacion})
        .then(response => {
            // this.state.currentStatus = cotizacion.idEstatus;
            // this.setState({currentStatus : this.state.currentStatus});
            this.getCotizaciones(localStorage.cli,this.state.currentStatus);
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    onClickStatus(e){
        this.state.currentEstatus = e.currentTarget.name;
        this.state.currentStatus = e.currentTarget.value;
        this.setState({
            currentStatus: this.state.currentStatus,
            currentEstatus: this.state.currentEstatus
        })
        this.getCotizaciones(localStorage.cli,this.state.currentStatus);
        // console.log(e.target);
        // e.currentTarget.value
    }

    onClickChangePage(e){
        if(e.currentTarget.name === "prev")
            this.state.currentPage = this.state.currentPage -1;
        else
            this.state.currentPage = this.state.currentPage + 1;

        this.setPagination();
    }

    moneyFormat(Qantity){
        let qnt = Qantity;
        
        if(qnt === undefined || qnt === null || qnt <= 0)
            qnt = 0 ;

        return '$'+(qnt).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

    render(){
        return(
            <div>
            <Routes/>
            <div id={"content"}>
                <Grid style={{width:"90%",display:"flex",marginLeft:"40px"}} container>
                    <Grid item xs={12} sm={1} md={1}>
                        <img src="group-users.svg" width="60%" height="60%" style={{marginTop:"20px",paddingLeft:"50px"}}></img>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                        <p style={{color:"#0f3b6e",fontSize:"25px",fontWeight:"bold",marginTop:"40px",paddingLeft:"25px"}} >Cotizaciones {this.state.empresa}</p>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <Button
                            variant="contained"
                            onClick={this.addCot}
                            style={{width:"92%",marginLeft:"80px",backgroundColor:"white",color:"#86d59c",fontSize:"10px",fontWeight:"bold",marginTop:"30px",height:"50px",border:"1px solid #86d59c"}}>
                            <AddCircleIcon style={{marginRight:"5px"}}></AddCircleIcon>
                             NUEVA COTIZACIÓN
                        </Button>
                    </Grid>
                </Grid>
                {/* formulario */}
                <Grid style = {{width:"90%",marginTop: "-20px",marginLeft:"90px"}} container>
                    <Grid item xs={12} sm={10} md={10}>
                        <FormControl variant="outlined" style = {{width: "100%",top:'31px',bottom:'30px'}}>
                            <InputLabel style={{marginLeft:'20px',top:"10px"}}>Cliente / Empresa</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="selCliente"
                                defaultValue={parseInt(localStorage.cli)}
                                onChange={this.changeCliente}
                            >
                                {
                                    this.state.clientes.map(data => {
                                        return (
                                            <MenuItem value={data.id}>{data.empresa}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <Button
                            style={{width:"92%",height:"88%",backgroundColor:"#164771",color:"white",marginLeft:'30px',marginTop:"28px"}}
                            onClick={this.updateClientData}
                        >
                            Modificar
                        </Button>
                    </Grid>
                </Grid>
                <Grid style = {{width:"92.5%",marginTop: "40px",marginLeft:"70px",marginBottom:"-20px"}} container>
                    <Grid item xs={12} sm={7} md={7}>
                        <InputLabel id="lblRepresentante" style={{marginLeft:'20px'}}>Representante Empresa</InputLabel>
                        <TextField id="txtRepresentante" name="representante" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}} 
                        value={this.state.representante}
                        onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <InputLabel id="lblPuesto" style={{marginLeft:'20px',marginBottom:"-15px"}}>Cargo / Puesto</InputLabel>
                        <TextField id="txtPuesto" name="puesto" margin="normal" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}} 
                        onChange={this.handleChange.bind(this)}
                        value={this.state.puesto} />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <InputLabel id="lblTelefono" style={{marginLeft:'20px',marginBottom:"-15px"}}>Telefono</InputLabel>
                        <TextField id="txtTelefono" name="telefono" margin="normal" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}} 
                        onChange={this.handleChange.bind(this)}
                        value={this.state.telefono} />
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                        <InputLabel style={{marginLeft:'20px',marginBottom:"-15px"}}>Ext.</InputLabel>
                        <TextField id="txtExt" name="extension" margin="normal" variant="outlined"  style = {{width: "92%"}} 
                        onChange={this.handleChange.bind(this)}
                        value={this.state.extension} />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <InputLabel style={{marginLeft:'20px',marginBottom:"-15px"}}>Celular</InputLabel>
                        <TextField id="txtCelular" name="celular" margin="normal" variant="outlined" style = {{width: "95%",marginLeft:'20px'}} 
                        onChange={this.handleChange.bind(this)}
                        value={this.state.celular} />
                    </Grid>
                    <Grid item xs={12} sm={7} md={7}>
                        <InputLabel id="lblDomicilio" style={{marginLeft:'20px',marginBottom:"-15px"}}>Domicilio</InputLabel>
                        <TextField id="txtDomicilio" name="domicilio" margin="normal" variant="outlined" style = {{width: "95%",marginLeft:'20px'}} 
                        onChange={this.handleChange.bind(this)}
                        value={this.state.domicilio} />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <InputLabel id="lblCiudad" style={{marginLeft:'20px',marginBottom:"-15px"}}>Ciudad</InputLabel>
                        <TextField id="txtCiudad" name="ciudad"  margin="normal" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}} 
                        onChange={this.handleChange.bind(this)}
                        value={this.state.ciudad} />
                    </Grid>
                    <Grid item xs={12} sm={7} md={7}>
                        <FormControl variant="outlined" style = {{width: "95%",marginLeft:'20px',top:'17px'}}>
                        <InputLabel id="lblEstado" style={{marginLeft:'20px',top:"10px"}}>Estado</InputLabel>
                            <Select
                                id="selEstado"
                                value={parseInt(localStorage.est)}
                                renderValue={(selected) => {console.log(selected);return(<div>{this.state.estado != "" ? this.state.estado : this.state.cliente.estado}</div>)}}
                                onChange={this.changeEstado}
                            >
                            {
                                this.state.estados.map(data => {
                                    return (
                                        <MenuItem value={data.id}>{data.estado}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <InputLabel style={{marginLeft:'20px',marginBottom:"-15px"}}>Email</InputLabel>
                        <TextField id="txtEmail" name="email" margin="normal" variant="outlined"  style = {{width: "95%",marginLeft:'20px'}} 
                        onChange={this.handleChange.bind(this)}
                        value={this.state.email} />
                    </Grid>
                </Grid>
               
                { JSON.parse(localStorage.currentUser)["user"]["idtipo"] == 1 ?
                <Grid style={{width:"95.5%",marginLeft:"70px",marginBottom:'45px'}} container>
                    <Grid item xs={12} sm={12} md={12} >
                        <FormControl variant="outlined" style = {{width: "95%",marginLeft:'20px',top:'31px'}}>
                            <InputLabel id="lblAsesor" style={{marginLeft:'20px',top:"10px"}}>Cambiar Asesor</InputLabel>
                                <Select
                                    id="selAsesor"
                                value={parseInt(localStorage.est)}
                                // renderValue={(selected) => {console.log(selected);return(<div>{this.state.estado != "" ? this.state.estado : this.state.cliente.estado}</div>)}}
                                    onChange={this.changeAsesor}
                                >
                                {
                                    this.state.asesor.map(data => {
                                        return (
                                            <MenuItem value={data}>{data.descripcion}</MenuItem>
                                            )
                                    })
                                }   
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                 :''
                }
                {!this.state.asesor.length > 0 ?
                <Grid style={{marginTop:"40px"}} container>
                </Grid>
                :''
                }
                
                {/* BUSQUEDA */}
                <Grid style={{width:"95.5%",marginLeft:"40px",textAlign:"center",display:"flex"}} container>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField id="txtSearch" name="search" variant="outlined" placeholder="Buscar Cotización"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment
                                style={{color:"#0f3b6e"}} 
                                position="end" >
                                    <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        style = {{width: "95%",marginLeft:'20px'}} 
                        value={this.state.search}
                        onChange={this.handleChange.bind(this)}
                        />
                    </Grid>
                </Grid>
                
                {/* estatus */}
                <Grid style={{width:"100%",height:"100px",display:"table",marginLeft:"10%",marginTop:"20px",marginBottom:"10px"}} container>
                    {
                        this.state.status.map(service => {
                            return (
                                this.state.currentStatus == service.idestatus ?
                                <Button
                                    onClick={this.onClickStatus}
                                    id={"btn"+service.estatus}
                                    value ={service.idestatus}
                                    name={service.estatus}
                                    variant="contained"
                                    style={{backgroundColor:"#164771",color:"white",height:"50px",marginLeft:'20px',marginBottom:"10px",marginTop:"10px"}}>
                                    <FolderOpenIcon style={{marginRight:"10px"}}></FolderOpenIcon>
                                        {service.estatus}
                                </Button>
                            :
                                <Button
                                    onClick={this.onClickStatus}
                                    id={"btn"+service.estatus}
                                    value ={service.idestatus}
                                    name={service.estatus}
                                    variant="contained"
                                    style={{backgroundColor:"white",color:"#164771",height:"50px",marginLeft:'20px',marginBottom:"10px",marginTop:"10px"}}>
                                    <FolderOpenIcon style={{marginRight:"10px"}}></FolderOpenIcon>
                                        {service.estatus}
                                </Button>
                            )
                        })
                    }
                    {
                        this.state.currentStatus == 0 ?
                        <Button
                            onClick={this.onClickStatus}
                            value ={0}
                            id={"btnTODOS"}
                            name={"TODOS"}
                            variant="contained"
                            style={{backgroundColor:"#164771",height:"50px",color:"white",marginLeft:'20px',marginBottom:"10px",marginTop:"10px"}}>
                            <FolderOpenIcon style={{marginTop:"0px"}}></FolderOpenIcon>
                                TODOS
                        </Button>
                        :
                        <Button
                            onClick={this.onClickStatus}
                            value ={0}
                            id={"btnTODOS"}
                            name={"TODOS"}
                            variant="contained"
                            style={{backgroundColor:"white",height:"50px",color:"#164771",marginLeft:'20px',marginBottom:"10px",marginTop:"10px"}}>
                            <FolderOpenIcon style={{marginTop:"0px"}}></FolderOpenIcon>
                                TODOS
                        </Button>
                    }
                    </Grid>
                
                {/* estatus actual y paginacion */}
                <Grid style={{textAlign:"center",display:"flex",marginTop:"-40px",marginLeft:"90px",width:"91%"}} container>
                    <Grid item xs={12} sm={6} md={6} style={{textAlign:"left",paddingLeft:"20px"}}>
                        <p style={{color:"#0f3b6e",fontSize:"25px",fontWeight:"bold"}} >{(this.state.currentEstatus).toUpperCase()}</p>            
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{textAlign:"right",paddingRight:"20px",display:"flex"}}>
                        <Grid item xs={12} sm={8} md={8}>
                        </Grid>
                        <Grid item xs={12} sm={1} md={1}>
                        {
                        this.state.prevPage > 0 ?
                            <IconButton 
                                style={{marginTop:"17px"}}
                                name={"prev"}
                                onClick={this.onClickChangePage}>
                                <NavigateBeforeIcon></NavigateBeforeIcon>
                            </IconButton>
                        :''
                        }
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} style={{textAlign:"center"}}>
                            <p style={{color:"#acacac",fontSize:"18px",fontWeight:"bold",marginTop:"30px"}} >
                                {this.state.currentPage} de {this.state.totalPages}
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={1} md={1}>
                        {
                        this.state.nextPage > 0 ?
                        <IconButton
                            style={{marginTop:"17px"}}
                            name={"next"}
                            onClick={this.onClickChangePage}>
                            <NavigateNextIcon></NavigateNextIcon>
                        </IconButton>
                        :''
                        }
                        </Grid>
                    </Grid>
                </Grid>

                {/* cotizaciones */}
                {
                this.state.cotizaciones.length > 0 ?
                <div>
                    <Table className="tbl" style={{width: "91%",marginLeft:'90px',border:"1px #acacac solid",marginBottom:"30px"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{color:"#3671b5",fontSize:"18px",fontWeight:"bold"}}># FOLIO</TableCell>
                                <TableCell align="center" style={{color:"#3671b5",fontSize:"18px",fontWeight:"bold"}}>REVISIÓN</TableCell>
                                <TableCell align="center" style={{color:"#3671b5",fontSize:"18px",fontWeight:"bold"}}>STATUS</TableCell>
                                <TableCell align="center" style={{color:"#3671b5",fontSize:"18px",fontWeight:"bold"}}>MONTO</TableCell>
                                <TableCell align="center" style={{color:"#3671b5",fontSize:"18px",fontWeight:"bold"}}>SEGUIMIENTO</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            this.state.cotizacionesPage.map(data => {
                            return(
                                <TableRow style ={ data.row_num % 2? { background : "#f4f6f9" }:{ background : "white" }}>
                                    <TableCell align="center">{data.folio}</TableCell>
                                    <TableCell align="center">{data.revision}</TableCell>
                                    <TableCell align="center">
                                        <FormControl variant="outlined" style = {{width: "98%",marginLeft:'20px'}}>
                                            {/* <InputLabel  id="lblEstado" style={{marginLeft:'20px'}}>Status</InputLabel> */}
                                            <Select
                                                id={"sel"+data.idCotizacion}
                                                name={data}
                                                value={data.idstatus}
                                                renderValue={(selected) => {return(<div>{data.status}</div>)}}
                                                onChange={this.onChangeStatusCotizacion}
                                            >
                                                {
                                                    this.state.status.map(service => {
                                                        return (
                                                            <MenuItem value={service.idestatus}>{service.estatus}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        {/* {data.status} */}
                                    </TableCell>
                                    <TableCell align="center">{this.moneyFormat(data.monto)}</TableCell>
                                    <TableCell align="center">{data.seguimiento}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            style={{color:"#0f3b6e"}} 
                                            value={data.idcotizacion} 
                                            onClick={this.clickCotizacion}><EditIcon></EditIcon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                )
                                }
                            )
                        }
                        </TableBody>
                    </Table>
                </div>
                :''
                }
                 </div>

</div>
        )
    }
}
export default Cotizaciones;