import React, { Component } from 'react';
import Routes from '../Routes';
import { environment } from '../config/config';
import { Authentication } from '../services/authentication';
import axios from 'axios';

class Usuarios extends Component{
    url = environment.url;

    usr = JSON.parse(localStorage.currentUser);

    constructor(props){

        super(props);
    
        if (!Authentication.isLogin()) {
            this.props.history.push('/');
        }

        this.state = {
        }

        document.getElementById('root').style.marginLeft = "0px";

        this.getUsers();
    }

    async getUsers(){
        await axios.get(`${this.url}/api/dashboard/cots/totales/mes?usr=${this.usr.user.idUsuario}&tipo=${this.usr.user.idTipo}&asr=${this.state.selasesor.id}`)
        .then(response => {
            this.setTotalesMes(response.data[0]);
        })
        .catch(error => {
            console.log(error.response)
        });
    }



    render(){
        return (
            <div>
                123
            </div>
        )
    }
}
export default Usuarios;
