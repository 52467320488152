import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Authentication } from '../services/authentication';
import Cotizador from '../pages/cotizador';

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={props => (
            Authentication.isLogin() ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute;