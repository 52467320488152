import React, { Component, Fragment } from 'react';
import { environment } from '../config/config'
import axios from 'axios';
import InputLabel from '@material-ui/core/InputLabel';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CardMedia from '@material-ui/core/CardMedia'
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Routes from '../Routes'
import $ from 'jquery';
import { borders } from '@material-ui/system';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Authentication } from '../services/authentication';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

class Clientes extends Component{
    
    url = environment.url;

    constructor(props){
        super(props);

        if (!Authentication.isLogin()) { 
            this.props.history.push('/');
        }

        this.state = {
            enProceso:[],
            presentadas:[],
            aceptadas:[],
            rechazadas:[],
            cotizaciones:[],
            estatus:'',
            estatusSel:{},
            ests:[],
            idEstatus:0,
            idEstatusTmp:0,
            idCotizacion:0,
            open: false,
            currentPage: 1,
            todosPerPage: 5,
            upperPageBound: 5,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
            clients:[],
            currentPage:1,
            rowsPerPage:10,
            totalRows:0,
            totalPages:1,
            initRow:1,
            endRow:10,
            prevPage:0,
            nextPage:0,
            clientsPage:[],
            pagination:false
        }
        this.handleChange = this.handleChange.bind(this);
        this.onClickChangePage = this.onClickChangePage.bind(this);
        this.onClickNewClient = this.onClickNewClient.bind(this);
        
        this.getClients();
    }

    async getClients(){
        document.getElementById('root').style.marginLeft = "0px";

        let usr = JSON.parse(localStorage.currentUser);
        await axios.get(`${this.url}/api/clientes/get?usr=${usr.user.idUsuario}&tipo=${usr.user.idTipo}`)
        .then(response => { 
            this.setState({
                clients:response.data,
                totalRows:response.data[0].total_rows
            })
            this.setPagination();
            // this.drawTableClients();
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    setPagination(){
        if(this.state.totalRows > 10){
            this.state.pagination = true;
            this.state.totalPages = this.state.totalRows / this.state.rowsPerPage;
            
            if(this.state.totalPages % 1 === 0 )
                this.state.totalPages = parseInt(this.state.totalPages)
            else
                this.state.totalPages = parseInt(this.state.totalPages)+1;

            if(this.state.currentPage > 1){
                
                if(this.state.totalPages === this.state.currentPage){
                    this.state.prevPage = this.state.totalPages - 1;
                    this.state.nextPage = 0;
                    this.state.initRow = (this.state.currentPage * this.state.rowsPerPage) - this.state.rowsPerPage + 1;
                    this.state.endRow = this.state.totalRows;
                }else{
                    this.state.prevPage = this.state.currentPage -1;
                    this.state.nextPage = this.state.currentPage + 1;
                    this.state.initRow = (this.state.currentPage * this.state.rowsPerPage) - this.state.rowsPerPage + 1;
                    this.state.endRow = this.state.currentPage * this.state.rowsPerPage;
                }
            }else{
                this.state.prevPage = 0;
                this.state.nextPage = 2;
                this.state.initRow = 1;
                this.state.endRow = 10;
            }

        }else{
            this.state.pagination = false;
            this.state.currentPage = 1;
            this.state.totalPages = 1;
            this.state.initRow = 1;
            this.state.endRow = this.state.totalRows;
            this.state.prevPage = 0;
            this.state.prevPage = 0;
        }

        let clisTmp = [];
        for(let i=this.state.initRow-1;i<this.state.endRow;i++){
            clisTmp.push(this.state.clients[i]);
        }
        this.state.clientsPage = clisTmp;
        this.setState({clientsPage:this.state.clientsPage})
    }

    drawTableClients(){
        console.log("!")
    }

    setClient(e,idcli,idest){
        localStorage.setItem('cli', idcli);
        localStorage.setItem('est', idest);
        this.props.history.push('/cotizaciones');

    }

    handleChange(e){
        this.setState({
            [e.target.name]: e.target.value
        });
        
        if(e.target.name === "search")
            this.search(e.target.value);
    }

    async search(search){
        let usr = JSON.parse(localStorage.getItem('currentUser'));

        await axios.get(`${this.url}/api/clientes/search?usr=${usr.user.idUsuario}&tipo=${usr.user.idTipo}&search=${search}`)
        .then(response => { 
            this.setState({
                clients:response.data,
                totalRows:response.data[0].total_rows
            })
            this.setPagination();
        })
        .catch(error => {
            console.log(error.response)
        });
    }

    onClickChangePage(e){
        if(e.currentTarget.name === "prev")
            this.state.currentPage = this.state.currentPage -1;
        else
            this.state.currentPage = this.state.currentPage + 1;

        this.setPagination();
    }

    onClickNewClient(e){
        this.props.history.push('/cliente');
    }

    moneyFormat(Qantity){
        let qnt = Qantity;
        
        if(qnt === undefined || qnt === null || qnt <= 0)
            qnt = 0 ;

        return '$'+(qnt).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

    render(){
        return (
            <div>
            <Routes/>
            <div id={"content"}>

                {/* titulo, nuevo cliente */}
                <Grid style={{display:"flex",marginLeft:"30px"}} container>
                    <Grid item xs={12} sm={1} md={1}>
                        <img src="group-users.svg" width="60%" height="60%" style={{marginTop:"10px",paddingLeft:"50px"}}></img>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1}>
                        <p style={{color:"#0f3b6e",fontSize:"25px",fontWeight:"bold",paddingLeft:"25px"}} >Clientes</p>
                    </Grid>
                    <Grid item xs={12} sm={10} md={10} style={{textAlign:"right"}}>
                        <Button
                            variant="contained"
                            onClick={this.onClickNewClient}
                            style={{backgroundColor:"white",color:"#86d59c",fontSize:"15px",fontWeight:"bold",marginTop:"30px",marginRight:"65px",height:"50px",border:"1px solid #86d59c"}}>
                            <AddCircleIcon style={{marginRight:"10px"}}></AddCircleIcon>
                             Nuevo Cliente
                        </Button>
                    </Grid>
                </Grid>
                
                {/* search */}
                <Grid style={{textAlign:"center",display:"flex"}} container>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField id="txtSearch" name="search" variant="outlined" placeholder="Buscar cliente" style = {{width: "95%",marginLeft:'20px'}} 
                        value={this.state.search}
                        onChange={this.handleChange}
                        />
                    </Grid>
                </Grid>

                {/* paginacion */}
                <Grid style={{textAlign:"right",marginLeft:"185px",width:"95%",marginBottom:"-50px"}} container>
                        <Grid item xs={12} sm={7} md={7}>
                        </Grid>
                        <Grid item xs={12} sm={1} md={1}>
                        {
                        this.state.prevPage > 0 ?
                            <IconButton 
                                style={{marginTop:"17px"}}
                                name={"prev"}
                                onClick={this.onClickChangePage}>
                                <NavigateBeforeIcon></NavigateBeforeIcon>
                            </IconButton>
                        :''
                        }
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} style={{textAlign:"center",marginLeft:"-70px",marginRight:"-100px"}}>
                            <p style={{color:"#acacac",fontSize:"18px",fontWeight:"bold",marginTop:"30px"}} >
                                {this.state.currentPage} de {this.state.totalPages}
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={1} md={1}>
                        {
                        this.state.nextPage > 0 ?
                        <IconButton
                            style={{marginTop:"17px"}}
                            name={"next"}
                            onClick={this.onClickChangePage}>
                            <NavigateNextIcon></NavigateNextIcon>
                        </IconButton>
                        :''
                        }
                        </Grid>
                    </Grid>

                {/* tabla clientes */}
                <Grid style={{textAlign:"center"}} container>
                    <Grid item xs={12} sm={12} md={12} style={{marginTop:"50px"}} >
                        <Table className="tbl" aria-label="simple table"  style={{width: "95%",marginLeft:'55px',border:"1px #acacac solid",marginBottom:"30px"}} >
                            <TableHead >
                                <TableRow>
                                    <TableCell align="center" style={{color:"#3671b5",fontSize:"15px",fontWeight:"bold"}}># CLIENTE</TableCell>
                                    <TableCell align="center" style={{color:"#3671b5",fontSize:"15px",fontWeight:"bold"}}>EMPRESA</TableCell>
                                    <TableCell align="center" style={{color:"#3671b5",fontSize:"15px",fontWeight:"bold"}}>REPRESENTANTE</TableCell>
                                    {/* <TableCell align="center">Seguimiento</TableCell> */}
                                    <TableCell align="center" style={{color:"#3671b5",fontSize:"15px",fontWeight:"bold"}}>VALOR</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody stripedRows>
                            {
                            this.state.clientsPage.map(data => {
                                return(
                                    <TableRow style ={ data.row_num % 2? { background : "#f4f6f9" }:{ background : "white" }}
                                        onClick ={(e)=> this.setClient(e,data.id,data.idestado)}>
                                        <TableCell align="center">{data.id}</TableCell>
                                        <TableCell align="center">{data.empresa}</TableCell>
                                        <TableCell align="center">{data.representante}</TableCell>
                                        {/* <TableCell align="center">{data.seguimiento}</TableCell> */}
                                        <TableCell align="center">{this.moneyFormat(data.valor)}</TableCell>
                                    </TableRow>
                                    )
                                }
                            )
                            }
                            </TableBody>    
                        </Table>
                </Grid>
                {/* <Grid item xs={12} sm={1} md={1}>
                </Grid> */}
                </Grid>

            </div>

            </div>
        )
    }
}
export default Clientes;