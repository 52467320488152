
import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font
} from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import { fontWeight, width } from "@material-ui/system";


const styles = StyleSheet.create({
    page: {
        paddingTop: 35,
        paddingBottom: 125,
        backgroundColor: "#ffffff"
    },
    image: {
        // height: 50,
        // width: 225,
        marginLeft:1
    },
    title:{
        color : "#515351",
        fontSize:31,
        marginLeft:"150px",
        fontFamily: "NunitoSemiBold",
    },
    normalBold:{
        fontFamily: "NunitoBold",
        fontSize: 9,
        color :"#515351"
    },
    normal:{
        fontFamily: "NunitoRegular",
        fontSize: 10,
        color :"#515351"
    },
    normalfooter:{
        fontFamily: "NunitoRegular",
        fontSize: 9,
        color :"#515351"
    },
    normalIntegra:{
        fontFamily: "NunitoRegular",
        fontSize: 9,
        marginLeft: "35px",
        color :"#515351"
    },
    normalDesp:{
        fontFamily: "NunitoRegular",
        fontSize: 9,
        marginLeft: "20px",
        color :"#515351"
    },
    paquete:{
        fontFamily: "NunitoBold",
        fontSize: 9,
        marginLeft:"5px",
        color :"#515351"
    },
    fecha:{
        fontFamily: "NunitoBold",
        fontSize: 9,
        marginLeft:"315px",
        color :"#515351"
    },
    folio:{
        fontFamily: "NunitoBold",
        color:"#82bf41",
        marginLeft:"7px",
        fontSize:9
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',

      },
        titleColTable:{
        fontFamily: "NunitoBold",
        fontSize: 10,
        color:"white",
        marginTop:".2px"
        // ,marginTop:"2px"
    },
    table: { 
        display: "table", 
        width: "100%", 
        borderRightWidth: 0, 
        borderBottomWidth: 0
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" ,
        width:"100%",
        height:"17px"
    },
    tableRowPs:{
        margin: "auto", 
        flexDirection: "row" ,
        width:"100%"
    },
    tableRowServices:{
        flexDirection: "row",
        width:"100%",
        height:"15px",
        marginTop:"1px"
    },
    tableCol: { 
    width: "25%",  
    borderLeftWidth: 0, 
    borderTopWidth: 0 
    }, 
    tableCell: { 
    margin: "auto", 
    marginTop: 5, 
    fontSize: 9
    },
    footer:{
        position:"absolute",
        bottom:15,
        // top:430,
        backgroundColor: "#e2e3e4",
        width:"100%",
        height:"17%"
    },
    borderBlue:{
        position: "absolute",/*or fixed*/
        right: 0,
        left: 600,
        width:"2%",
        height:"239px",
        backgroundColor: "#46646c"
    },
    borderGreen:{
        position: "absolute",/*or fixed*/
        right: 0,
        left: 600,
        marginTop:"238",
        width:"2%",
        height:"240px",
        backgroundColor: "#82bf41"
    },
    borderGray:{
        position: "absolute",/*or fixed*/
        right: 0,
        left: 600,
        marginTop:"477",
        width:"2%",
        height:"240",
        backgroundColor: "#a5a5a4"
    },
    marca:{
        position: "absolute",/*or fixed*/
        right: 0,
        left: 500,
        marginTop:"90",
        width:"20%",
        height:"70%",
        // backgroundColor: "#a5a5a4"
    },
    titleColTableServsR:{
        fontFamily:"NunitoRegular",fontSize:"10px",color:"white",marginTop:".2px"
    },
    titleColTableServsSB:{
        fontFamily:"NunitoSemiBold",fontSize:"10px",color:"white",marginTop:".2px"
    },
});

// const mod = getMods(t => ({
   
// }));
// https://github.com/googlefonts/nunito/blob/master/fonts/TTF/Nunito-Regular.ttf?raw=true
Font.register('NunitoSemiBold.ttf', { family: 'NunitoSemiBold' } );
Font.register('nunitoBold.ttf', { family: 'NunitoBold' } );
// Font.register('NunitoBold.woff', { family: 'NunitoBold' } );
Font.register('NunitoRegular.ttf', { family: 'NunitoRegular' } );
// Font.register('http://themes.googleusercontent.com/static/fonts/nunito/v4/kpI87QY2ce-mk2ZnKb-r0g.ttf', { family: 'NunitoBold' } );

export default function PdfDocument(props) {
    // const [mods] = React.useState([]);

    // const mods = props.data.modulos;
    // console.log("pdf props", props.data);
    return (
        <Document>
            <Page size="letter" style={styles.page}>
                <View style={{marginLeft:"50px",marginRight:"50px",fontSize:11}}>
                    <View style={{display: "flex",flexDirection: "row",marginTop:"30px"}}>
                            <Text style={styles.fecha}>{props.data.detalles.fecha}</Text>
                            <Text style={styles.folio}>Propuesta No. {props.data.detalles.folio+'-'+props.data.detalles.revision}</Text>
                    </View>                
                    <View style={{display: "flex",flexDirection: "row"}}>
                            {/* <Image source="rbt.png" style={styles.image}/> */}
                            <Image source="logo.png" style={styles.image}/>
                            <Text style={styles.title}>Cotización</Text>
                    </View>
                    <View style={{display: "flex",flexDirection: "row"}}>
                        <Text style={styles.normalBold}>{props.data.detalles.representante}</Text>
                    </View>
                    <View>
                        <Text style={styles.normal}>{props.data.detalles.empresa}</Text>
                    </View>
                    <View style={{marginTop:"7px"}}>
                        <Text style={styles.normal}>
                            Estimado(a) {props.data.detalles.representante}, agradecemos la oportunidad que nos brinda para presentar esta propuesta de servicios.
                        </Text>
                        <Text style={styles.normal}>
                            Ponemos a su consideración la presente, con el fin de llevar a cabo el servicio que a continuación se detalla:
                        </Text>
                    </View>
                    {
                        props.data.plataforma.length > 0 || props.data.verificacion.length > 0 || props.data.socioeconomico.length > 0 || props.data.psicometrico.length > 0 ?
                            <View style={{marginTop:"25px"}}>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={{backgroundColor:"#a5a5a4",width:"31%",textAlign:"center"}}>
                                            <Text style={styles.titleColTable}>DESCRIPCIÓN</Text> 
                                        </View>
                                        <View style={{backgroundColor:"#83bf38",width:"23%",textAlign:"center"}}> 
                                            <Text style={styles.titleColTable}>CANTIDAD</Text> 
                                        </View>  
                                        <View style={{backgroundColor:"#a5a5a4",width:"20%",textAlign:"center"}}> 
                                            <Text style={styles.titleColTable}>P. UNITARIO</Text> 
                                        </View> 
                                        <View style={{backgroundColor:"#4f646c",width:"26%",textAlign:"center"}}> 
                                           <Text style={styles.titleColTable}>IMPORTE</Text> 
                                        </View> 
                                    </View>
                                    {
                                        props.data.verificacion.length > 0 ?
                                        <View style={styles.tableRowServices} > 
                                            <View style={{width:"31%",marginLeft:"5px",display:"flex",flexDirection: "row"}}> 
                                                <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                                                <Text style={styles.normal}>Verificaciones</Text> 
                                            </View>
                                            <View style={{width:"23%",textAlign:"center"}}> 
                                                <Text style={styles.normal}></Text> 
                                            </View> 
                                            <View style={{width:"20%",textAlign:"center"}}>
                                                <Text style={styles.normal}></Text>
                                            </View>
                                            <View style={{width:"26%",textAlign:"center"}}>
                                                <Text style={styles.normal}></Text> 
                                            </View>  
                                        </View>
                                    :
                                        <View></View>                                            
                                    }
                                    {
                                    props.data.verificacion.length > 0 ?
                                        props.data.verificacion.map(data => {
                                            return (
                                                <View style={styles.tableRowServices} > 
                                                    <View style={{width:"31%",textAlign:"center"}}> 
                                                        <Text style={styles.normal}>{data.paquete}</Text> 
                                                    </View>
                                                    <View style={{width:"23%",textAlign:"center"}}> 
                                                        <Text style={styles.normal}>{data.cantidad}</Text> 
                                                    </View> 
                                                    <View style={{width:"20%",textAlign:"center"}}>
                                                        <Text style={styles.normal}>{'$ '+(data.costo).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text>
                                                    </View>
                                                    <View style={{width:"26%",textAlign:"center"}}>
                                                        <Text style={styles.normal}>{'$ '+(data.total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text> 
                                                    </View>  
                                                </View>
                                            )
                                        })
                                    :
                                        <View></View>
                                    }
                                    {
                                        props.data.psicometrico.length > 0 ?
                                        <View style={styles.tableRowServices} > 
                                            <View style={{width:"31%",marginLeft:"5px",display:"flex",flexDirection: "row"}}> 
                                                <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                                                <Text style={styles.normal}>Psicometría</Text> 
                                            </View>
                                            <View style={{width:"23%",textAlign:"center"}}> 
                                                <Text style={styles.normal}></Text> 
                                            </View> 
                                            <View style={{width:"20%",textAlign:"center"}}>
                                                <Text style={styles.normal}></Text>
                                            </View>
                                            <View style={{width:"26%",textAlign:"center"}}>
                                                <Text style={styles.normal}></Text> 
                                            </View>  
                                        </View>
                                    :
                                        <View></View>                                        
                                    }
                                    {
                                    props.data.psicometrico.length > 0 ?
                                        props.data.psicometrico.map(data => {
                                                return (
                                                    <View style={styles.tableRowServices}>
                                                        <View style={{width:"31%",textAlign:"center"}}> 
                                                            <Text style={styles.normal}>{data.paquete}</Text> 
                                                        </View>
                                                        <View style={{width:"23%",textAlign:"center"}}>
                                                            <Text style={styles.normal}>{data.cantidad}</Text> 
                                                        </View>
                                                        <View style={{width:"20%",textAlign:"center"}}>
                                                            <Text style={styles.normal}>{'$'+(data.total/data.cantidad).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text> 
                                                        </View>
                                                        <View style={{width:"26%",textAlign:"center"}}> 
                                                            <Text style={styles.normal}>{'$'+(data.total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text> 
                                                        </View> 
                                                    </View> 
                                                )
                                        })
                                        :
                                        <View></View>
                                    }
                                    {
                                    props.data.socioeconomico.length > 0 ?
                                    <View style={styles.tableRowServices} > 
                                        <View style={{width:"31%",marginLeft:"5px",display:"flex",flexDirection: "row"}}> 
                                            <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                                            <Text style={styles.normal}>Socioeconómicos</Text> 
                                        </View>
                                        <View style={{width:"23%",textAlign:"center"}}> 
                                            <Text style={styles.normal}></Text> 
                                        </View> 
                                        <View style={{width:"20%",textAlign:"center"}}>
                                            <Text style={styles.normal}></Text>
                                        </View>
                                        <View style={{width:"26%",textAlign:"center"}}>
                                            <Text style={styles.normal}></Text> 
                                        </View>  
                                    </View>
                                    :
                                    <View></View>
                                    }
                                    {
                                    props.data.socioeconomico.length > 0 ?
                                        props.data.socioeconomico.map(data => {
                                            return (
                                                <View style={styles.tableRowServices}> 
                                                    <View style={{width:"31%",textAlign:"center"}} > 
                                                        <Text style={styles.normal}>{data.paquete}</Text> 
                                                    </View>
                                                    <View style={{width:"23%",textAlign:"center"}}> 
                                                        <Text style={styles.normal}>{data.cantidad}</Text> 
                                                    </View> 
                                                    <View style={{width:"20%",textAlign:"center"}}>
                                                        <Text style={styles.normal}>{'$'+(data.costo).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text> 
                                                    </View>
                                                    <View style={{width:"26%",textAlign:"center"}}> 
                                                        <Text style={styles.normal}>{'$'+(data.total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text> 
                                                    </View> 
                                                </View> 
                                                    )
                                                })
                                    :
                                        <View></View>
                                    }
                                    {
                                    props.data.plataforma.length > 0 ?
                                        <View style={styles.tableRowServices}> 
                                            <View style={{width:"31%",marginLeft:"5px",display:"flex",flexDirection: "row",}}> 
                                                <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                                                <Text style={styles.normal}>Plataforma 24/7</Text> 
                                            </View> 
                                            <View style={{width:"23%",textAlign:"center"}}> 
                                                <Text></Text> 
                                            </View> 
                                            <View style={{width:"20%",textAlign:"center"}}> 
                                                <Text></Text> 
                                            </View>
                                            <View style={{width:"26%",textAlign:"center"}}> 
                                                <Text></Text> 
                                            </View>
                                        </View>
                                    :   <View></View>
                                    }
                                    {
                                    props.data.plataforma.length > 0 ?
                                        props.data.plataforma.map(data => {
                                            return (
                                                <View>
                                                    <View style={styles.tableRowServices}>
                                                        <View style={{width:"31%",marginLeft:"20px",marginTop:"-1px"}}> 
                                                            <Text style={styles.normal}>{data.paquete}</Text> 
                                                        </View> 
                                                        <View style={{width:"16%",textAlign:"center"}}> 
                                                            <Text style={styles.normal}>{data.colaboradores}</Text> 
                                                        </View> 
                                                        <View style={{width:"26%",textAlign:"center"}}> 
                                                            <Text style={styles.normal}>{'$'+(data.costo).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text> 
                                                        </View> 
                                                        <View style={{width:"20%",textAlign:"center"}}> 
                                                            <Text style={styles.normal}>{'$'+(data.total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text> 
                                                        </View> 
                                                    </View>
                                            {
                                            data.modulos.map(mod => {
                                                return (
                                                    <View style={{display: "flex",flexDirection: "row",marginLeft:"10px"}}>
                                                        {mod.activo === 1 ?
                                                            <Image source="check.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                                                        :
                                                            <Image source="uncheck.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                                                        }
                                                        <Text style={styles.normal}>{mod.modulo}</Text> 
                                                    </View>
                                                )
                                            })
                                            }
                                            </View>
                                            )
                                            })
                                    :
                                        <View></View>
                                    }
                                </View> 
                            </View>
                        :
                            <View></View>
                    }
                    {
                    props.data.detalles.total > 0 ?
                        <View style={{width:"25%",textAlign:"right",marginLeft:"75%",marginTop:"5px",marginBottom:"25px"}}>
                            <View style={{borderBottomWidth:1.5,borderBottomColor:"#83bf38",paddingBottom:"2px"}}>
                                <Text style={styles.normal}>SUBTOTAL: {'$'+(props.data.detalles.total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text>  
                                <Text style={styles.normal}>IVA: {'$'+(props.data.detalles.total * .16).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text>  
                            </View>
                                <Text style={styles.normalBold}>INVERSIÓN: {'$'+((props.data.detalles.total * .16) + (props.data.detalles.total)).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}</Text>  
                        </View>
                    : 
                        <View></View>
                    }


                    {
                    props.data.reclutamiento.length > 0 ? 
                        <View style={{marginTop:"15px"}}>
                            <View style={styles.table}> 
                                <View style={styles.tableRow}> 
                                    <View style={{backgroundColor:"#a5a5a4",width:"30%",textAlign:"center"}}>
                                        <Text style={styles.titleColTable}>DESCRIPCIÓN</Text> 
                                    </View> 
                                    <View style={{backgroundColor:"#83bf38",width:"20%",textAlign:"center"}}> 
                                        <Text style={styles.titleColTable}>TIPO</Text> 
                                    </View> 
                                    <View style={{backgroundColor:"#a5a5a4",width:"25%",textAlign:"center"}}> 
                                        <Text style={styles.titleColTable}>GARANTÍA</Text> 
                                    </View> 
                                    <View style={{backgroundColor:"#4f646c",width:"25%",textAlign:"center",fontWeight: 700,fontSize:10}}> 
                                        <Text style={styles.titleColTable}>HONORARIOS</Text> 
                                    </View>
                                </View>
                                {
                                <View style={styles.tableRow}> 
                                    <View style={{width:"30%",marginLeft:"5px"}}> 
                                        <Text style={styles.normalBold}>Reclutamiento</Text> 
                                    </View> 
                                    <View style={{width:"20%"}}>
                                        <Text></Text> 
                                    </View>
                                    <View style={{width:"25%"}}> 
                                        <Text></Text> 
                                    </View> 
                                    <View style={{width:"25%"}}> 
                                        <Text></Text> 
                                    </View>
                                </View>
                                }
                                {
                                props.data.reclutamiento.map(data => {
                                    return (
                                        <View style={styles.tableRow}> 
                                            <View style={{width:"30%"}}> 
                                                <Text></Text> 
                                            </View> 
                                            <View style={{width:"20%",textAlign:"center"}}> 
                                                <Text style={styles.normal}>{data.paquete}</Text> 
                                            </View> 
                                            <View style={{width:"25%",textAlign:"center"}}>
                                                <Text style={styles.normal}>{data.garantia}</Text> 
                                            </View>
                                            <View style={{width:"25%",textAlign:"center"}}> 
                                                <Text style={styles.normal}>{data.honorarios}</Text> 
                                            </View> 
                                        </View> 
                                        )
                                    })
                                }
                            </View>
                        </View>
                        :
                        <View></View>
                        }
                        {/* <View style={{marginTop:"20px"}}>
                            <Text style={styles.normal}>Queremos reiterarle el compromiso de brindar un servicio de calidad, y cumplir con sus expectativas; Asegurando que contará con lo mejor de nuestro equipo.</Text>
                        </View> */}
                </View> 


                    <View style={styles.marca}>
                        <Image source="marcaagua.png" />
                    </View>
                    
                    <View style={styles.footer} fixed>
                        <View style={{display:"flex",flexDirection:"row",}}>
                            <View style={{width:"25%",marginLeft:"50px"}}>
                                <Text style={styles.normalBold}>Metodos de Pago</Text>
                                <Text style={styles.normalfooter}>GISGC Grupo Integradora de Servicio GC S.A. de C.V.</Text>
                                <Text style={{marginTop:"5px"}}></Text>
                                <Text style={styles.normal}>Bancomer</Text>
                                <Text style={styles.normal}>Cuenta 0111625810</Text>
                                <Text style={styles.normal}>Clabe 012760001116258109</Text>
                            </View>   
                            <View style={{width:"33%",marginLeft:"20px"}}>
                                <Text style={styles.normalBold}>Contacto</Text>
                                <Text style={styles.normal}>Tel. (662)302 80 57</Text>
                                <View style={{display:"flex",flexDirection: "row",marginBottom:"5px"}}> 
                                    <Image source="whatsapp.png" style={{width:"10px",height:"10px",marginTop:"2px",marginLeft:"1px",marginRight:"6px"}}/>
                                    <Text style={styles.normal}>{props.data.detalles.celular}</Text> 
                                </View>
                                <Text style={styles.normal}>contacto.sonora@integrarh.mx</Text>
                                <Text style={{marginTop:"5px"}}></Text>
                                <Text style={styles.normal}>José obregón 141 Col. Villa Satélite</Text>
                                <Text style={styles.normal}>C.P. 83200 Hermosillo, Sonora</Text>
                                <Text style={{marginTop:"4px"}}></Text>
                                <Text style={styles.normal}>¡Gracias por su confianza!</Text>
                            </View> 
                            <View style={{width:"33%",marginLeft:"5px"}}>
                                {/* {{borderBottomWidth:.5,borderBottomColor:"#d2d2d1",paddingBottom:"5px"}}> */}
                                <Text style={styles.normalIntegra}>Por integra RH</Text>
                                {/* <View style={{display: "flex",flexDirection: "row"}}>
                                    <Image source="FirmaRoberto.png" style={styles.image}/>
                                </View> */}
                                <View>
                                    <Image source={props.data.detalles.asesor+".png"} style={{width:"150px",height:"80px",borderBottomWidth:1,borderBottomColor:"#000000"}}/>
                                    {/* <Image source="FirmaRoberto.png" style={styles.pageBackground}/> */}
                                </View>
                                <Text style={styles.normalDesp}>{props.data.detalles.descripcion}</Text>
                            </View>         
                        </View>
                    </View>
            </Page>
            
            <Page size="letter" style={styles.page}>
                <View style={{marginLeft:"50px",marginRight:"50px",fontSize:11}}>
                    {/* logo */}
                    <View style={{display:"flex",flexDirection: "row"}}>
                        <Image source="logo.png" style={{width:"45%",height:"90%"}}/>
                    </View>
                    <View style={{width:"100%",textAlign:"left",marginTop:"1px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,marginLeft:"10px",color:"#515351"}}>Descripción de los Servicios Cotizados</Text> 
                    </View>

                    {/* estudio socioeconomico */}
                    <View style={{width:"100%",textAlign:"left",marginLeft:"10px",marginTop:"25px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,color:"#83bf38"}}>Estudio Socioeconómico</Text>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,marginTop:"1px",color:"#515351"}}>Elaboración de Estudio Socioeconómico referente a la información proporcionada por el aspirante al puesto, ello incluye:</Text> 
                    </View> 
                    <View style={{width:"100%",textAlign:"left",display:"flex",flexDirection: "row",marginTop:"5px",marginLeft:"10px"}}>
                        <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351"}}>Revisión a la documentación proporcionada por el aspirante.</Text>  
                    </View>
                    <View style={{width:"100%",textAlign:"left",display:"flex",flexDirection: "row",marginTop:"1px",marginLeft:"10px"}}>
                        <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351"}}>Visita de un trabajador social al domicilio proporcionado por el aspirante.</Text>  
                    </View> 
                    <View style={{width:"100%",textAlign:"left",display:"flex",flexDirection: "row",marginTop:"1px",marginLeft:"10px"}}>
                        <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351"}}>Elaboración de informe con resultado de búsqueda.</Text>  
                    </View>

                    {/* reclutamiento */}
                    <View style={{width:"100%",textAlign:"left",marginTop:"25px",marginLeft:"10px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,color:"#83bf38"}}>Reclutamieto Digital</Text>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,marginTop:"1px",color:"#515351"}}>Reclutamiento y selección de personal a otro nivel, donde la tecnología es nuestra principal herramienta para localizar e identificar al talento idóneo para su organización.</Text> 
                    </View>
                    <View style={{width:"100%",textAlign:"left",marginTop:"10px",marginLeft:"10px"}}>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,marginTop:"1px",color:"#515351"}}>Contamos con un equipo y proceso de selección profesional efectivo y confiable, donde evaluamos las conductas, conocimientos, hablidades e historial profesional de los aspirantes a las vacantes para asi atraer a los candidatos más calificados y capaces de ocupar en su organización.</Text> 
                    </View>

                    {/* psicometria */}
                    <View style={{width:"100%",textAlign:"left",marginTop:"25px",marginBottom:"10px",marginLeft:"10px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,color:"#83bf38"}}>Evaluación Psicométrica</Text>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,marginTop:"1px",color:"#515351"}}>Diagnóstico integral por medio de evaluación de inteligencia, personalidad, aptitud, comportamiento y valores, a través de una batería confiable de pruebas psicométricas</Text> 
                    </View>
                    
                    <View style={{width:"100%",marginTop:"25px",marginBottom:"10px",display:"flex",flexDirection:"row"}}>
                        
                        <View style={{width:"47%"}}>
                            <View style={styles.table}> 
                                <View style={styles.tableRow}> 
                                    <View style={{backgroundColor:"#83bf38",display:"flex",flexDirection: "row",width:"91.3%"}}>
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:10,color:"white",marginTop:".2px",marginLeft:"20%"}}>Pruebas  -  </Text> 
                                        <Text style={{fontFamily:"NunitoBold",fontSize:10,color:"white",marginTop:".2px"}}>Psicometría Básica</Text> 
                                    </View> 
                                </View>
                                <View style={styles.tableRowPs}> 
                                    <View style={{borderLeft:.5,borderLeftColor:"#515351",borderBottom:.5,borderBottomColor:"#515351",borderRight:.5,borderRightWidthColor:"#515351",width:"91.3%"}}>
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"5px"}}>Comportamiento</Text> 
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"5px"}}>Inteligencia</Text> 
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"5px"}}>Personalidad</Text> 
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"5px"}}>Coeficiente de ventas y habilidades</Text> 
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"5px"}}>Competencias laborales</Text> 
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"5px"}}>Índice de confianza y honestidad</Text> 
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"5px"}}>Factor y habilidades directas y de liderazgo</Text> 
                                    </View> 
                                </View>
                            </View>
                        </View>

                        <View style={{width:"47%"}}>
                            <View style={styles.table}> 
                                <View style={styles.tableRow}>
                                    <View style={{backgroundColor:"#83bf38",display:"flex",flexDirection: "row",width:"108.5%"}}>
                                        <Text style={{fontFamily:"NunitoRegular",fontSize:10,color:"white",marginTop:".2px",marginLeft:"20%"}}>Pruebas  -  </Text> 
                                        <Text style={{fontFamily:"NunitoBold",fontSize:10,color:"white",marginTop:".2px"}}>Psicometría Premium</Text>
                                    </View> 
                                </View>
                                <View style={styles.tableRowPs}> 
                                    <View style={{borderLeft:.5,borderLeftColor:"#515351",borderRight:.5,borderRightColorColor:"#515351"}}>
                                        <View style={{display:"flex",flexDirection: "row",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Terman</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Habilidades</Text>
                                        </View> 
                                        <View style={{display:"flex",flexDirection: "row",marginTop:".1px",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Beta</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Inteligencia</Text>
                                        </View>
                                        <View style={{display:"flex",flexDirection: "row",marginTop:".1px",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Gordon</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Personalidad</Text>
                                        </View> 
                                        <View style={{display:"flex",flexDirection: "row",marginTop:".1px",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Cleaver</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Competencia</Text>
                                        </View> 
                                        <View style={{display:"flex",flexDirection: "row",marginTop:".1px",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Valores Allport</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Valores Morales</Text>
                                        </View> 
                                        <View style={{display:"flex",flexDirection: "row",marginTop:".1px",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Raven</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Razonamiento</Text>
                                        </View> 
                                        <View style={{display:"flex",flexDirection: "row",marginTop:".1px",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Barsit</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Conocimiento</Text>
                                        </View> 
                                        <View style={{display:"flex",flexDirection: "row",marginTop:".1px",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Lifo</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,width:"50%",color:"#515351",marginLeft:"10px"}}>Ligerazgo</Text>
                                        </View> 
                                        <View style={{marginTop:".1px",borderBottom:.5,borderBottomColorColor:"#515351"}}>
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"10px"}}>PPV</Text> 
                                            <Text style={{fontFamily:"NunitoRegular",fontSize:11,color:"#515351",marginLeft:"10px"}}>Personalidad CP Corto</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>                    
                    </View>
                </View>

                <View style={styles.marca}>
                        <Image source="marcaagua.png" />
                </View>

            </Page>
            
            <Page size="letter" style={styles.page}>
                <View style={{marginLeft:"50px",marginRight:"50px",fontSize:11}}>
                    
                    {/* logo */}
                    <View style={{display: "flex",flexDirection: "row"}}>
                            <Image source="logo.png" style={{width:"45%",height:"90%"}}/>
                    </View>
                    <View style={{width:"100%",textAlign:"left",marginTop:"1px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,marginLeft:"10px",color:"#515351"}}>Descripción de los Servicios Cotizados</Text> 
                    </View>
                    
                    {/* platsaforma 24/7 */}
                    <View style={{width:"90%",textAlign:"left",marginTop:"25px",marginLeft:"10px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,color:"#83bf38"}}>Plataforma 24/7</Text>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,marginTop:"1px",color:"#515351"}}>Software as a Service / Software como servicio para tener el control total sobre aspectos claves y esenciales en tema de Recursos Humanos.</Text> 
                    </View>
                    <View style={{width:"100%",textAlign:"left",marginTop:"10px",marginLeft:"10px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,marginTop:"1px",color:"#515351"}}>Módulos:</Text> 
                    </View>
                    <View style={{width:"100%",textAlign:"left",marginTop:"5px",display:"flex",flexDirection:"row",marginLeft:"20px"}}>
                        <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,marginTop:"1px",color:"#515351"}}>Plantilla Laboral</Text> 
                    </View>
                    <View style={{width:"85%",textAlign:"left",marginTop:"5px",marginLeft:"10px"}}>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,marginTop:"1px",color:"#515351"}}>(Información laboral, expediente laboral, herramientas de trabajo, uniformes de trabajo, vacaciones, evaluaciones, médico RH).</Text> 
                    </View>
                    <View style={{width:"100%",textAlign:"left",marginTop:"5px",display:"flex",flexDirection:"row",marginLeft:"20px"}}>
                        <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,marginTop:"1px",color:"#515351"}}>Jurídico RH</Text> 
                    </View>
                    <View style={{width:"100%",textAlign:"left",marginTop:"5px",display:"flex",flexDirection:"row",marginLeft:"20px"}}>
                        <Image source="selected.png" style={{width:"5.5px",height:"5.5px",marginTop:"4px",marginRight:"5px"}}/>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,marginTop:"1px",color:"#515351"}}>Evaluaciones</Text> 
                    </View>

                    {/* nomina */}
                    <View style={{width:"90%",textAlign:"left",marginTop:"25px",marginLeft:"10px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,color:"#83bf38"}}>Nómina</Text>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,marginTop:"1px",color:"#515351"}}>Módulo de timbrado de nómina multiempresa en la nube, con integración a módulo de plantilla laboral; configuración flexible para todo tipo de empresa con actualizaciones constantes sin costo adicional.</Text> 
                    </View>

                    {/* Integra Care */}
                    <View style={{width:"85%",textAlign:"left",marginTop:"25px",marginLeft:"10px"}}>
                        <Text style={{fontFamily:"NunitoBold",fontSize:11,color:"#83bf38"}}>Integra Care</Text>
                        <Text style={{fontFamily:"NunitoRegular",fontSize:11,marginTop:"1px",color:"#515351"}}>Programa de lealtad, beneficios y valores agregados con atención a salud, entretenimiento, y atención a imprevistos. </Text> 
                    </View>
                </View>
                <View style={styles.marca}>
                        <Image source="marcaagua.png" />
                    </View>
            </Page>



        </Document>
    );
}