import React, { Component, Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer'
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import { ListItemIcon} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import { Authentication } from '../src/services/authentication';
  
  let option = window.location.pathname;

  let user = localStorage.currentUser;

  const drawerWidth = 240;

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor:"#0f3b6e"
    // backgroundColor:"#0f3b6e"
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    imgProfile:{
        borderRadius: 50,
        backgroundColor:"white",
        width:"100px",
        marginLeft:"25%"
    },
    divProfile:{
        marginTop:"20px",
        marginBottom:"5px",
        width: "90%",
        // border: "3px solid #73AD21",
    }
  }));

export default function Routes(){   
  let op = window.location.pathname;
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [selected] = React.useState('');
  
    const handleDrawerOpen = () => {
      document.getElementById('root').style.marginLeft = "240px";
      setOpen(true);

    };
  
    const handleDrawerClose = () => {
      document.getElementById('root').style.marginLeft = "0px";
      setOpen(false);
    };

    const handleRouter = (e) => {
        window.location.pathname = "/"+e.currentTarget.id;
    }

    const handleLogOut = (e) =>{
      Authentication.logout();
    }

    return (
      <div id="contents" style={op === "/cotizacion" || op === "/cotizador" ? {position:"absolute"} : {position:"absolute"}  }>
        <Router>{
                <IconButton
                id={"btnRouter"}
                style = {{position: "absolute",left:"10px",top:"0px",color:"#0f3b6e",zIndex:"1"}}
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
            >
            <MenuIcon />
          </IconButton>
          }
          <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon style={{color:"#f4f6f9"}} /> : <ChevronRightIcon style={{color:"#f4f6f9"}}/>}
          </IconButton>
        </div>
        <div className={classes.divProfile}>
            <img src="profile.png" className={classes.imgProfile}></img>
            <p style={{color:"#f4f6f9",fontSize:"16px",marginLeft:"20%"}} >
             {JSON.parse(localStorage.currentUser)['user'].name}
            <IconButton onClick={handleLogOut}>
              <ExpandMoreIcon style={{color:"#f4f6f9"}}></ExpandMoreIcon>
            </IconButton>
            </p>
            
        </div>
        <List style={{marginLeft:"7%"}}>
          <ListItem button
            id="dashboard" 
            name="dashboard" 
            onClick={handleRouter}>
            {
            op === "/dashboard" ?
              <ListItemIcon style={{display:"flex"}}>
                <img src="dashboard-green.svg" width="18px" height="18px" style={{marginTop:"20px",marginLeft:"10px",marginRight:"5px"}}></img>
                <p style={{color:"#86d59c",fontSize:"18px"}} >Dashboard</p>
              </ListItemIcon> 
            :
              <ListItemIcon style={{display:"flex"}}>
                <img src="dashboard-gray.svg" width="18px" height="18px" style={{marginTop:"20px",marginLeft:"10px",marginRight:"5px"}}></img>
                <p style={{color:"#f4f6f9",fontSize:"18px"}} >Dashboard</p>
              </ListItemIcon>
            }
          </ListItem>
          <ListItem button
            id="clientes" 
            name="clientes" 
            onClick={handleRouter}>
            {
            op === "/clientes" ?
              <ListItemIcon style={{display:"flex"}}>
                <img src="clients-green.svg" width="18px" height="18px" style={{marginTop:"20px",marginLeft:"10px",marginRight:"5px"}}></img>
                <p style={{color:"#86d59c",fontSize:"18px"}} >Clientes</p>
              </ListItemIcon> 
            :
              <ListItemIcon style={{display:"flex"}}>
                <img src="clients-gray.svg" width="18px" height="18px" style={{marginTop:"20px",marginLeft:"10px",marginRight:"5px"}}></img>
                <p style={{color:"#f4f6f9",fontSize:"18px"}} >Clientes</p>
              </ListItemIcon>  
            }
          </ListItem>
          <ListItem button 
            id="cotizaciones" 
            name="cotizaciones" 
            onClick={handleRouter}>
              {
              op === "/cotizaciones" ? 
                <ListItemIcon style={{display:"flex"}}>
                  <img src="invoice-green.svg" width="18px" height="18px" style={{marginTop:"20px",marginLeft:"10px",marginRight:"5px"}}></img>
                  <p style={{color:"#86d59c",fontSize:"18px"}} >Cotizaciones</p>
                </ListItemIcon>
                :
                <ListItemIcon style={{display:"flex"}}>
                  <img src="invoice-gray.svg" width="18px" height="18px" style={{marginTop:"20px",marginLeft:"10px",marginRight:"5px"}}></img>
                  <p style={{color:"#f4f6f9",fontSize:"18px"}} >Cotizaciones</p>
                </ListItemIcon>
              }

          </ListItem>
          <ListItem button style={{display:"none"}}
            id="configuracion" 
            name="configuracion" 
            // onClick={handleRouter}
            >
            {
            op === "/configuracion" ? 
              <ListItemIcon style={{display:"flex"}} >
                <img src="config-green.svg" width="18px" height="18px" style={{marginTop:"20px",marginLeft:"10px",marginRight:"5px"}}></img>
                <p style={{color:"#86d59c",fontSize:"18px"}} >Configuración</p>
              </ListItemIcon>
            :
              <ListItemIcon style={{display:"flex"}}>
                <img src="config-gray.svg" width="18px" height="18px" style={{marginTop:"20px",marginLeft:"10px",marginRight:"5px"}}></img>
                <p style={{color:"#f4f6f9",fontSize:"18px"}} >Configuración</p>
              </ListItemIcon>
            }
          </ListItem>
      </List>
        <div>
            <img src="integrarh.png" style={{position:"absolute",left:"15%",bottom: "1%",width:"65%"}}></img>
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
        <Switch>
          <Route exact path="/clientes">
            </Route>
            <Route exact path="/cotizaciones">
            </Route>
            <Route exact path="/cotizador">
            </Route>
            <Route exact path="/cotizacion">
            </Route>
            <Route exact path="/dashboard">
            </Route>
            <Route exact path="/usuarios">
            </Route>
        </Switch>
      </Router>
      </div>
    )
}
